import { useEffect, useState } from 'react';

import { trackEvent } from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';

/**
 * Sends a tracking event to Segment when the component is
 * shown and only fires one time. Subsequent state changes
 * and/or renders will not fire the event again. This is great
 * to use when tracking "show <ComponentName>" analytical events
 *
 * However, if the component is removed from the VirtualDOM,
 * but then re-shown, then this tracking event will fire again
 * @param event - AnalyticsEvent to fire
 * @param eventData - optional object containing additional data to send
 */
export const useTrackEventOnMount = (
  event: AnalyticsEvent,
  eventData?: Record<string, unknown> | undefined
): void => {
  const [didSendEvent, setDidSendEvent] = useState<boolean>(false);
  useEffect(() => {
    if (!didSendEvent && event) {
      trackEvent(event, eventData);
      setDidSendEvent(true);
    }
  }, [didSendEvent, event, eventData]);
};
