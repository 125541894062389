import { gql } from '@apollo/client';

export const GENERATE_ANONYMOUS_IDENTITY = gql`
  mutation generateAnonymousIdentity($anonymousUserId: String) {
    generateAnonymousIdentity(anonymousUserId: $anonymousUserId) {
      anonymousUserIdentity {
        id
      }
      validationErrors {
        field
        message
      }
    }
  }
`;

export const ASSOCIATE_USER_TO_ANONYMOUS_ID = gql`
  mutation associateUserToAnonymousId($anonymousUserId: String!) {
    associateUserToAnonymousId(anonymousUserId: $anonymousUserId) {
      anonymousUserIdentity {
        id
      }
      validationErrors {
        field
        message
      }
    }
  }
`;
