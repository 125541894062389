import { Asset, Entry } from 'contentful';
import get from 'lodash/get';

import { client } from './index';

const contentTypes = {
  tastemaker: 'tastemaker',
  tastemakerPick: 'tastemakerPick',
};

export type PersonLinkedDataModel = {
  alumniOf?: string;
  awards?: string[];
  birthDate?: string;
  birthPlace?: {
    lat: number;
    lon: number;
  };
  description?: string;
  gender?: string;
  heightInches?: number;
  homepage?: string;
  honorificPrefix?: string;
  honorificSuffix?: string;
  jobTitle?: string;
  name: string;
  nationality?: string;
};

export type PersonLinkedDataEntry = Entry<PersonLinkedDataModel>;

export type TastemakerModel = {
  attributionId: string;
  blurb: string;
  handle: string;
  personLinkedData: PersonLinkedDataEntry;
  profileImage: Asset;
  shortDescription?: string;
  slug: string;
  specializations?: string[];
};

export type TastemakerEntry = Entry<TastemakerModel>;

type TastemakerPickModel = {
  comment?: string;
  customName?: string;
  sku: string;
  tastemaker: TastemakerEntry;
};

export type TastemakerPickEntry = Entry<TastemakerPickModel>;

/**
 * Fetches all Tastemakers
 */
export const loadTastemakers = async () => {
  const response = await client.getEntries<TastemakerModel>({
    content_type: contentTypes.tastemaker,
  });

  return response.items;
};

/**
 * Fetches all picks  for a given Tastemaker's slug
 * @param slug - slug of the tastemaker that the picks belong to
 */
export const loadTasteMakerPicks = async (slug: string) => {
  const tastemakerSlugFilter = `fields.${contentTypes.tastemaker}.fields.slug`;
  const tastemakerContentTypeFilter = `fields.${contentTypes.tastemaker}.sys.contentType.sys.id`;

  const tastemakerPicksResponse = await client.getEntries<TastemakerPickModel>({
    content_type: contentTypes.tastemakerPick,
    [tastemakerContentTypeFilter]: contentTypes.tastemaker,
    [tastemakerSlugFilter]: slug,
  });

  const picks = tastemakerPicksResponse.items;

  const arrayOfSkus = picks.map(pick => get(pick, 'fields.sku', ''));
  const contentfulDataForSkus = picks.map(pick => {
    // necessary as a sku might not load from backend, this allows for matching
    const comment = get(pick, 'fields.comment', '');
    const customName = get(pick, 'fields.customName', '');
    const sku = get(pick, 'fields.sku', '');
    return { comment, customName, sku };
  });
  return [arrayOfSkus, contentfulDataForSkus];
};

/**
 * Fetches a TasteMaker and its picks for a given Tastemaker's slug
 * @param slug - slug of the tastemaker that the picks belong to
 */
export const loadTastemakerWithPicks = async (slug: string) => {
  const tastemakerSlugFilter = `fields.${contentTypes.tastemaker}.fields.slug`;
  const tastemakerContentTypeFilter = `fields.${contentTypes.tastemaker}.sys.contentType.sys.id`;

  const tastemakerPicksResponse = await client.getEntries<TastemakerPickModel>({
    content_type: contentTypes.tastemakerPick,
    include: 2,
    [tastemakerContentTypeFilter]: contentTypes.tastemaker,
    [tastemakerSlugFilter]: slug,
  });

  const picks = tastemakerPicksResponse.items;

  const tastemaker = picks.length > 0 ? picks[0].fields.tastemaker : undefined;

  return { picks, tastemaker };
};
