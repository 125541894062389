import { BrandModel } from 'explorer/types/Brand';
import { DefaultBrandTab, DefaultProfileTab } from 'explorer/types/Profile';
import { Logger } from 'test/integration/utils/Logger';

import { pageRoutes } from 'lib/routes';

import {
  ExplorerContentOwner,
  ExplorerContentOwnerType,
  Profile,
} from 'types/generated/api';

export const getAccountViewUrl = (
  tab?: string,
  type?: ExplorerContentOwnerType
): string => {
  const defaultTab =
    type === ExplorerContentOwnerType.BRAND
      ? DefaultBrandTab
      : DefaultProfileTab;
  return tab && tab !== defaultTab
    ? pageRoutes.account.profile.tab.displayUrl(tab)
    : pageRoutes.account.profile.landing.displayUrl();
};

export const getBrandProfileUrl = (brandSlug: string, tab?: string): string => {
  return tab && tab !== DefaultBrandTab
    ? pageRoutes.brand.detail.tab.displayUrl(brandSlug, undefined, tab).pathname
    : pageRoutes.brand.detail.landing.displayUrl(brandSlug).pathname;
};
export const getProfileUrl = (username: string, tab?: string): string => {
  return tab && tab !== DefaultProfileTab
    ? pageRoutes.profile.tab.displayUrl({
        tab,
        username,
      })
    : pageRoutes.profile.landing.displayUrl({
        username,
      });
};

export const getProfileCollectionUrlByProfile = (
  collectionSlug?: string,
  profile?: Profile
): string => {
  if (!profile) {
    Logger.warn('getProfileCollectionUrlByProfile failed with null profile');
    return '';
  }
  if (profile.type === ExplorerContentOwnerType.BRAND && profile.brandSlug) {
    return pageRoutes.brand.collection.displayUrl(
      profile.brandSlug,
      collectionSlug
    );
  }
  if (profile.username) {
    return pageRoutes.profile.collection.displayUrl(
      profile.username,
      collectionSlug
    );
  }
  Logger.warn(
    `getProfileCollectionUrlByProfile failed with profile id: ${profile.id}`
  );
  return '';
};

export const getProfileUrlByProfile = (
  profile?: Profile,
  tab?: string
): string => {
  if (!profile) {
    return '';
  }
  if (profile.isProfileOwner) {
    return getAccountViewUrl(tab, profile.type);
  }
  if (profile.type === ExplorerContentOwnerType.BRAND && profile.brandSlug) {
    return getBrandProfileUrl(profile.brandSlug, tab);
  }
  if (profile.username) {
    return getProfileUrl(profile.username, tab);
  }
  Logger.warn(`getProfileUrlByProfile failed with profile id: ${profile.id}`);
  return '';
};

export const getProfileUrlByOwner = (
  owner?: ExplorerContentOwner,
  tab?: string
): string => {
  if (!owner) {
    return '';
  }

  if (owner.type === ExplorerContentOwnerType.BRAND && owner.brandSlug) {
    return getBrandProfileUrl(owner.brandSlug, tab);
  }
  return getProfileUrl(owner.ownerName, tab);
};

interface ProfileDataProps {
  brandModel?: BrandModel;
  profile: Profile;
}

export const getProfileDisplayName = ({ profile }: ProfileDataProps) => {
  return profile.displayName || profile.username;
};

// TODO (ningchong): add unit test
export const getProfileName = ({ brandModel, profile }: ProfileDataProps) => {
  return profile.type === ExplorerContentOwnerType.BRAND
    ? brandModel?.title || profile.displayName || profile.username
    : profile.username;
};

export const getProfileSubtitle = ({ profile }: ProfileDataProps) => {
  return profile.type !== ExplorerContentOwnerType.BRAND
    ? profile.displayName
    : undefined; // brand profiles don't currently have a subtitle
};

export const getProfileDescription = ({
  brandModel,
  profile,
}: ProfileDataProps) => {
  return profile.type === ExplorerContentOwnerType.BRAND
    ? brandModel?.description || profile.biography || ''
    : profile.biography;
};
