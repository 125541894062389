import pickBy from 'lodash/pickBy';
import urlParse from 'url-parse';

import {
  Experiments,
  ExperimentName,
  experimentValueResolvers,
  DEFAULT_BUCKET,
  DEFAULT_VERSION,
  ExperimentValueType,
} from 'lib/experiment/experimentConfig';
import { ExperimentValues } from 'lib/experiment/experimentValueResolver';
import { printToConsole, SEVERITY_LEVEL } from 'lib/utils/Logger';

import {
  getItem,
  SessionStorageKeys,
  setItem,
} from '../sessionStorageWrapper/sessionStorageWrapper';

const EXPERIMENT_PREFIX = 'experiment_';

export const toExperiment = (name: string, value: ExperimentValueType) => {
  return {
    bucket: DEFAULT_BUCKET,
    name,
    value,
    version: DEFAULT_VERSION,
  };
};

export const toExperimentKey = (key: string) =>
  key.startsWith(EXPERIMENT_PREFIX) &&
  Object.keys(ExperimentName).includes(key.substring(EXPERIMENT_PREFIX.length))
    ? key.substring(EXPERIMENT_PREFIX.length)
    : undefined;

export const getExperimentsFromQueryParams = (): Partial<Experiments> => {
  const experimentsQueryParams: Partial<Experiments> = {};
  const query = urlParse(document.location.toString(), true).query || {};
  for (const queryKey in query) {
    if (!query.hasOwnProperty(queryKey)) {
      continue;
    }
    const experimentKey = toExperimentKey(queryKey);
    const rawValue = query[queryKey];
    if (experimentKey && rawValue !== undefined) {
      try {
        const experimentResolver = experimentValueResolvers[experimentKey];
        // Provided form is either a single scalar value that can be parsed by scalar experiment value resolvers
        // or a serialized json array that follows the protocol of experiment values
        const experimentValues: ExperimentValues = rawValue.startsWith('[')
          ? JSON.parse(rawValue).map((jsonObj: Record<string, unknown>) =>
              pickBy(
                jsonObj,
                (value, key) =>
                  (key === 'key' || key === 'value') &&
                  typeof value === 'string'
              )
            )
          : [{ key: 'dummy', value: rawValue }];
        experimentsQueryParams[experimentKey] = toExperiment(
          experimentKey,
          experimentResolver(experimentValues).value()
        );
      } catch (error) {
        printToConsole({
          error,
          errorOrMessage: `Error getting experiment values from query params: ${queryKey} - ${rawValue}`,
          severity: SEVERITY_LEVEL.INFO,
        });
      }
    }
  }
  return experimentsQueryParams;
};

export const setExperimentsToSessionStorage = (
  experiments: Partial<Experiments>
) => {
  try {
    const serializedExperiments = JSON.stringify(experiments);
    setItem(SessionStorageKeys.QueryParamExperiments, serializedExperiments);
  } catch (error) {
    printToConsole({
      error,
      errorOrMessage: 'Error setting feature flags to session storage',
      severity: SEVERITY_LEVEL.INFO,
    });
  }
};

export const getExperimentsFromSessionStorage = (): Partial<Experiments> => {
  try {
    const serializedExperiments = getItem(
      SessionStorageKeys.QueryParamExperiments
    );
    return serializedExperiments ? JSON.parse(serializedExperiments) : {};
  } catch (error) {
    printToConsole({
      error,
      errorOrMessage: 'Error parsing experiments from session storage',
      severity: SEVERITY_LEVEL.INFO,
    });
    return {};
  }
};

/**
 * Gets the feature flags inside of session storage as well as the feature flags
 * in the query params. Merges them together and saves them in session storage.
 */
export const mergeExperimentParams = (): Partial<Experiments> => {
  try {
    const savedValues = getExperimentsFromSessionStorage();
    const fromQueryParams = getExperimentsFromQueryParams();
    const mergedFeatureFlags = {
      ...savedValues,
      ...fromQueryParams,
    };
    setExperimentsToSessionStorage(mergedFeatureFlags);
    return mergedFeatureFlags;
  } catch (error) {
    printToConsole({
      error,
      errorOrMessage: 'Error updating experiment values from query params',
      severity: SEVERITY_LEVEL.INFO,
    });
    return {};
  }
};
