import { Asset, Entry } from 'contentful';

export interface ILocalizableLink {
  displayText: string;
  id?: string;
  linkType: string;
  noFollow?: boolean;
  pathName?: string;
  queryString?: string;
  slug?: string;
  startsWith?: string;
}

export type LocalizableLinkEntry = Entry<ILocalizableLink>;

// Used by modules (e.g Banner Modules) to determine the text color
export enum TextColor {
  Dark = 'Dark',
  Light = 'Light',
}

/*
 * a range of size options that map to our _typography mixins
 */
export enum ButtonThemeEnum {
  cta = 'cta',
  primary = 'primary',
  secondary = 'secondary',
}

export enum ButtonTypeEnum {
  button = 'button',
  textLink = 'textLink',
}

export enum FontFamilyEnum {
  sans = 'sans',
  serif = 'serif',
}

export enum TextSizeEnum {
  extraLarge = 'extraLarge',
  extraSmall = 'extraSmall',
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum TextWeightEnum {
  bold = 'bold',
  light = 'light',
  medium = 'medium',
  normal = 'normal',
  semiBold = 'semiBold',
}

export type ImageLinkModel = {
  image: Asset;
  link: LocalizableLinkEntry;
};

export type ImageLinkEntry = Entry<ImageLinkModel>;
