import Link from 'next/link';
import { ReactElement } from 'react';

import { pageRoutes } from 'lib/routes';

type CategoryLandingLinkProps = {
  categorySlug: string;
  children?: ReactElement;
  queryParams?: unknown;
};

const CategoryLandingLink = (props: CategoryLandingLinkProps) => {
  const { categorySlug, queryParams } = props;
  const displayUrl = pageRoutes.category.landing.displayUrl(
    categorySlug,
    queryParams
  );

  return (
    <Link href={displayUrl} prefetch={false}>
      {props.children}
    </Link>
  );
};

export default CategoryLandingLink;
