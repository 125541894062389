import Router from 'next/router';

import Logger from 'lib/utils/Logger';

export const navigateToCheckout = (url: string) => {
  try {
    window.location.href = url;
  } catch (error) {
    Logger.error('Error navigate to checkout', error);
    Router.push('/error');
  }
};
