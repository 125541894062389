import Link from 'next/link';
import { ReactElement } from 'react';

import { pageRoutes } from 'lib/routes';

type BrandLinkProps = {
  brandSlug?: string;
  children?: ReactElement;
  queryParams?: unknown;
  startsWith?: string;
};

// /brand/ghost-democracy
const brandDetailLink = (props: BrandLinkProps) => {
  const { brandSlug, queryParams } = props;
  const displayUrl = pageRoutes.brand.detail.landing.displayUrl(
    brandSlug,
    queryParams
  );

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {props.children}
    </Link>
  );
};

const brandListLink = (props: BrandLinkProps) => {
  const { startsWith } = props;
  const displayUrl = pageRoutes.brand.list.displayUrl();

  // /brands#j
  if (startsWith) {
    return (
      <Link
        href={`${displayUrl}#${startsWith}`}
        passHref
        prefetch={false}
        shallow
      >
        {props.children}
      </Link>
    );
  }

  // /brands
  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {props.children}
    </Link>
  );
};

const BrandLink = (props: BrandLinkProps) => {
  const { brandSlug } = props;

  if (brandSlug) {
    return brandDetailLink(props);
  }

  return brandListLink(props);
};

export default BrandLink;
