import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useCallback, useEffect, useState } from 'react';

/**
 * Will lock scrolling except for the element that the ref is set on.
 * Returns a refCallback that should be set on the scrollable element.
 * @param shouldLock  - e.g. if the modal is open, shouldLock should be true
 */
const useScrollLock = (shouldLock: boolean) => {
  const [node, setNode] = useState<HTMLElement>();

  const refCallback = useCallback(elem => {
    if (elem) {
      setNode(elem);
    }
  }, []);

  useEffect(() => {
    if (node) {
      if (shouldLock) {
        disableBodyScroll(node);
      } else {
        clearAllBodyScrollLocks();
      }
    }

    return clearAllBodyScrollLocks;
  }, [shouldLock, node]);

  return { refCallback };
};

export default useScrollLock;
