import { ChangeEvent, useState } from 'react';

import {
  emailValidator,
  loginPasswordValidator,
  passwordValidator,
} from 'lib/validators';

export const useEmailInput = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);

  const validateEmail = () => {
    const newEmailError = emailValidator(email) || null;

    if (emailError !== newEmailError) {
      setEmailError(newEmailError);
    }

    return newEmailError;
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return {
    email,
    emailError,
    handleEmailChange,
    setEmail,
    setEmailError,
    validateEmail,
  };
};

export const usePasswordInput = (isLogin = false) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const validator = isLogin ? loginPasswordValidator : passwordValidator;

  const validatePassword = () => {
    const newPasswordError = validator(password) || null;

    if (passwordError !== newPasswordError) {
      setPasswordError(newPasswordError);
    }

    return newPasswordError;
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return {
    handlePasswordChange,
    password,
    passwordError,
    setPassword,
    setPasswordError,
    validatePassword,
  };
};
