import classnames from 'classnames';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';

import SpinnerSvg from 'assets/icons/spinner.inline.svg';

import styles from './SpinnerInline.module.scss';

type SpinnerInlineProps = { isDark?: boolean; size?: BUTTON_SIZE };

const SpinnerInline = ({
  isDark,
  size = BUTTON_SIZE.LARGE,
}: SpinnerInlineProps) => (
  <div
    className={classnames([
      styles.spinnerIcon,
      {
        [styles.isDark]: isDark,
        [styles.isSmall]: size === BUTTON_SIZE.SMALL,
        [styles.isMedium]: size === BUTTON_SIZE.MEDIUM,
        [styles.isLarge]: size === BUTTON_SIZE.LARGE,
      },
    ])}
  >
    <SpinnerSvg />
  </div>
);

export default SpinnerInline;
