import Link from 'next/link';
import { ReactNode } from 'react';

import { getProductInternalLinkUrl } from 'lib/utils/product/pageUtils';

import { ProductQueryStringParams } from 'types/app';
import {
  ProductPdpFragment,
  ProductVariantPdpFragment,
} from 'types/generated/api';

export type ProductLinkProps = {
  brandSlug: string;
  children: ReactNode;
  familySlug: string;
  includeVariantIdAttribute?: boolean;
  productId: string;
  productSlug: string;
  queryStringParams?: ProductQueryStringParams;
  selectedVariant?: Pick<ProductVariantPdpFragment, 'selectedOptions' | 'sid'>;
  showDiscontinued?: boolean;
  supressColorAttribute?: boolean;
} & Partial<Pick<ProductPdpFragment, 'hierarchicalCategories' | 'options'>>;

const ProductLink = ({
  brandSlug,
  children,
  familySlug,
  hierarchicalCategories,
  includeVariantIdAttribute,
  options,
  productId: productSid,
  productSlug,
  queryStringParams,
  selectedVariant,
  supressColorAttribute,
}: ProductLinkProps) => {
  const displayUrl = getProductInternalLinkUrl(
    {
      brandSlug,
      familySlug,
      hierarchicalCategories,
      options,
      sid: productSid,
      slug: productSlug,
    },
    queryStringParams?.color,
    selectedVariant,
    supressColorAttribute,
    includeVariantIdAttribute
  );

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {children}
    </Link>
  );
};

export type ProductLinkForVariantProps = ProductLinkProps & {
  queryStringParams: { variant_id: string } & ProductQueryStringParams;
};

export const ProductLinkForVariant = (props: ProductLinkForVariantProps) => {
  return <ProductLink {...props} />;
};

export default ProductLink;
