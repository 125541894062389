import { gql } from '@apollo/client';

import fragments from './fragments';

const ADD_TO_CART = gql`
  mutation addToCart($lineItems: [CheckoutLineItemInput!]!) {
    addToCart(lineItems: $lineItems) @client
  }
`;

const APPLY_PROMO_CODE = gql`
  mutation applyPromoCode($promoCode: String!) {
    applyPromoCode(promoCode: $promoCode) @client {
      checkout {
        ...checkoutFields
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${fragments.checkoutFields}
`;

const BOOKMARK_ADD_TO_LIST = gql`
  mutation addBookMarkTolist($input: BookmarkInput!) {
    createBookmark(input: $input) {
      bookmark {
        createdAt
        id
      }
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

const BOOKMARK_DELETE_FROM_LIST = gql`
  mutation deleteBookmarkFromList($id: ID!) {
    deleteBookmark(id: $id) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

const EMAIL_LIST_SIGN_UP = gql`
  mutation createEmailSignUp($input: EmailSignUpInput!) {
    createEmailSignUp(input: $input) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

const REFRESH_ME = gql`
  mutation refreshMe {
    refreshMe @client
  }
`;

const REMOVE_DISCOUNT_CODE = gql`
  mutation removePromoCode($promoCode: String!) {
    removePromoCode(promoCode: $promoCode) @client {
      checkout {
        ...checkoutFields
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${fragments.checkoutFields}
`;

const SYNC_CART_ID = gql`
  mutation syncCartId($newCartId: String!) {
    syncCartId(newCartId: $newCartId) @client
  }
`;

const UPDATE_CART_ITEM_QUANTITIES = gql`
  mutation updateCartItemQuantity(
    $variantIds: [ID]
    $newQuantity: Number
    $quantityMap: Map
  ) {
    updateCartItemQuantity(
      variantIds: $variantIds
      newQuantity: $newQuantity
      quantityMap: $quantityMap
    ) @client {
      checkout {
        id
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        ...customerFields
      }
      success
      validationErrors {
        field
        message
      }
    }
    updateCustomerLocal(input: $input) @client
  }
  ${fragments.customerFields}
`;

export {
  ADD_TO_CART,
  APPLY_PROMO_CODE,
  BOOKMARK_ADD_TO_LIST,
  BOOKMARK_DELETE_FROM_LIST,
  EMAIL_LIST_SIGN_UP,
  REFRESH_ME,
  REMOVE_DISCOUNT_CODE,
  SYNC_CART_ID,
  UPDATE_CART_ITEM_QUANTITIES,
  UPDATE_CUSTOMER,
};
