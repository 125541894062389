// Warning: BaseLink is intended only for when a *root* level page URL matches identically to a filename under /pages.
//          For anything requiring path or querystring parameters, create your own component in this directory.

import Link from 'next/link';
import { ReactElement } from 'react';

import Logger from 'lib/utils/Logger';

type BaseLinkProps = {
  children: ReactElement;
  passHref?: boolean;
  pathName: string;
};

const ensureRelativePathname = (pathName: string) =>
  pathName[0] === '/' ? pathName : `/${pathName}`;

const BaseLink = ({ children, passHref, pathName }: BaseLinkProps) => {
  if (!children) {
    Logger.error(
      'BaseLink component requires .children property; Detected usage without it.'
    );
    return null;
  }

  if (!pathName) {
    Logger.error(
      'BaseLink component requires .pathName property; Detected usage without it.'
    );
    return null;
  }

  const relativePathname = ensureRelativePathname(pathName);
  return (
    <Link href={relativePathname} passHref={passHref} prefetch={false}>
      {children}
    </Link>
  );
};

export default BaseLink;
