/**
 * Only consider the following values as true. All other values are treated as false.
 * - numbers: 1 or in string forms
 * - string "true", "on", "yes", ignoring cases and whitespaces
 */
const isTruthy = (value: unknown): boolean => {
  switch (typeof value) {
    case 'boolean':
      return value;
    case 'number':
      return value === 1;
    case 'string':
      switch (value.trim().toLowerCase()) {
        case '1':
        case 'true':
        case 'on':
        case 'yes':
          return true;
        default:
          return false;
      }
    default:
      return false;
  }
};

export default isTruthy;
