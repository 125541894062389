import { gql } from '@apollo/client';

import { customerFields, profileFields } from './fragments.gql';

export const GET_CUSTOMER = gql`
  query getCustomer {
    me {
      ...customerFields
      profiles {
        ...profileFields
      }
    }
  }
  ${customerFields}
  ${profileFields}
`;
