import { sha256 } from 'crypto-hash';

import { LineItemCustomAttributeKeys } from 'data/graphql/enums';
import {
  CheckoutLineItemInput,
  CustomAttribute,
} from 'data/graphql/types.shopify';

import { getBundleId } from 'lib/cart/bundlesUtils';
import { tryConvertSidToId } from 'lib/shopify/utils';
import Logger from 'lib/utils/Logger';

type CartCustomAttributeValue = {
  items: Array<{ bundleId?: string; quantity: number; variantSid: string }>;
};

/**
 * This util is used for adding the cart information to the custom attributes
 * of every line item in the cart. This information is needed by the CarrierSevice.
 * The CarrierService determines the shipping fee of an order. As of now, the
 * CarrierService calculates the sub-total of an order and uses that value to determine the shipping fee.
 * However, when Shopify calls the CarrierService, it does not provide all line items
 * in the cart - it will split the order by fulfillment service. We work around this limitation
 * by adding a custom attribute, which contains the entire cart info, to every line item.
 */
export class CartCustomAttributeUtil {
  static async addCartCustomAttributeToLineItems(
    lineItems: CheckoutLineItemInput[],
    checkoutId: string
  ): Promise<CheckoutLineItemInput[]> {
    const builder = new CartCustomAttributeUtil(lineItems, checkoutId);
    return await builder.addCartCustomAttributeToLineItems();
  }

  lineItems: CheckoutLineItemInput[];
  checkoutId: string;

  constructor(lineItems: CheckoutLineItemInput[], checkoutId: string) {
    this.lineItems = lineItems;
    this.checkoutId = checkoutId;
  }

  private async addCartCustomAttributeToLineItems(): Promise<
    CheckoutLineItemInput[]
  > {
    const cartAttribute = this.buildCartAttribute();
    const sessionIdAttribute = await this.buildSessionIdAttribute();

    return this.lineItems.map(lineItem => {
      const { customAttributes, ...rest } = lineItem;

      const updatedCustomAttributes = this.addCartAttributeToCustomAttributes(
        customAttributes,
        cartAttribute,
        sessionIdAttribute
      );

      return {
        customAttributes: updatedCustomAttributes,
        ...rest,
      };
    });
  }

  private buildCartAttribute(): CustomAttribute {
    const cartCustomAttributeValue: CartCustomAttributeValue = {
      items: this.lineItems.map(lineItem => {
        let variantSid = tryConvertSidToId(lineItem.variantId);

        if (!variantSid) {
          Logger.error('Error converting encoded sid to sid');
          variantSid = '';
        }

        const bundleId = getBundleId(lineItem);

        return {
          bundleId,
          quantity: lineItem.quantity,
          variantSid,
        };
      }),
    };

    return {
      key: LineItemCustomAttributeKeys.CART_DATA,
      value: JSON.stringify(cartCustomAttributeValue),
    };
  }

  private async buildSessionIdAttribute(): Promise<CustomAttribute> {
    const digest = `${this.checkoutId}${Date.now()}`;
    const hash = await sha256(digest, { outputFormat: 'hex' });
    return {
      key: LineItemCustomAttributeKeys.SESSION_ID,
      value: hash,
    };
  }

  private addCartAttributeToCustomAttributes(
    customAttributes: CustomAttribute[],
    cartAttribute: CustomAttribute,
    sessionIdAttribute: CustomAttribute
  ): CustomAttribute[] {
    const filteredCustomAttributes = customAttributes.filter(
      customAttribute => {
        return (
          customAttribute.key !== cartAttribute.key &&
          customAttribute.key !== sessionIdAttribute.key
        );
      }
    );

    filteredCustomAttributes.push(cartAttribute);
    filteredCustomAttributes.push(sessionIdAttribute);

    return filteredCustomAttributes;
  }
}
