import { ApolloError, useMutation } from '@apollo/client';
import { useEffect } from 'react';

import Logger from 'lib/utils/Logger';

import { GENERATE_ANONYMOUS_IDENTITY } from './Identifier.gql';

import {
  GenerateAnonymousIdentityMutation,
  GenerateAnonymousIdentityMutationVariables,
} from 'types/generated/api';

/**
 * Send the anonymousUserId that is in localStorage to the API.
 */
export const useSendAnonymousIdToApi = (anonymousUserId?: string | null) => {
  const [sendAnonymousIdToApi, { data, error }] = useMutation<
    GenerateAnonymousIdentityMutation,
    GenerateAnonymousIdentityMutationVariables
  >(GENERATE_ANONYMOUS_IDENTITY);

  // Since we do not reset the anonymousId when the user signs out,
  // the mutation should only run one time when the app boots.
  useEffect(() => {
    if (anonymousUserId && sendAnonymousIdToApi) {
      sendAnonymousIdToApi({ variables: { anonymousUserId } });
    }
  }, [anonymousUserId, sendAnonymousIdToApi]);

  useEffect(() => {
    logSendingAnonymousIdError(error, data);
  }, [error, data]);
};

const logSendingAnonymousIdError = (
  error?: ApolloError,
  response?: GenerateAnonymousIdentityMutation | null
) => {
  if (error) {
    Logger.error(
      'Error in useSendAnonymousUserIdToApi: failed to send anonymousId to api',
      error
    );
  }

  const validationErrors =
    response?.generateAnonymousIdentity?.validationErrors;
  if (validationErrors && validationErrors.length > 0) {
    Logger.error(
      `Error in useSendAnonymousUserIdToApi: received validation errors trying to send anonymousId to api - ${JSON.stringify(
        validationErrors
      )}`
    );
  }
};
