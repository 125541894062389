export enum LineItemCustomAttributeKeys {
  CART_DATA = '_cart',
  GIFT_CARD_ID = '_gift_card_id',
  GIFT_CARD_NOTE = 'gift_card_note',
  PROMO_ELIGIBLE = '_promotion_eligible',
  PROMO_MESSAGE = '_promotion_messaging',
  PROMO_NAME = '_promotion_name',
  SELECTED_BUNDLE_ID = '_selected_bundle_id',
  SESSION_ID = '_session_id',
}

export enum LineItemCustomAttributeValues {
  PROMO_ELIGIBLE_FALSE = 'false',
  PROMO_ELIGIBLE_TRUE = 'true',
}
