import { Entry } from 'contentful';

import { client, ContentfulContentTypeEnum } from './index';

type CategoryLandingPageModel = {
  metaDescription: string;
  metaTitle: string;
  modules: Array<Entry<unknown>>;
  slug: string;
};

export type CategoryLandingPageEntry = Entry<CategoryLandingPageModel>;

/**
 * Fetches the Category Landing Page data model in Contentful
 */
export const loadCategoryLandingPage = async (
  categorySlug: string
): Promise<CategoryLandingPageEntry> => {
  const response = await client.getEntries<CategoryLandingPageModel>({
    content_type: ContentfulContentTypeEnum.categoryLandingPage,
    'fields.slug': categorySlug.toLowerCase(),
    include: 3,
    limit: 1,
  });
  const categoryLandingPage = response.items[0];
  return categoryLandingPage;
};

export const loadAllCategoryLandingPages = async (options?: {
  [name: string]: unknown;
}): Promise<CategoryLandingPageEntry[]> => {
  const response = await client.getEntries<CategoryLandingPageModel>({
    content_type: ContentfulContentTypeEnum.categoryLandingPage,
    ...options,
  });

  return response.items;
};
