import { Asset, Entry } from 'contentful';

import {
  ButtonThemeEnum,
  ButtonTypeEnum,
  FontFamilyEnum,
  ISiteWideBannerModalModel,
  LocalizableLinkEntry,
  TextSizeEnum,
  TextWeightEnum,
} from 'lib/contentful/index';

export enum UltimateBannerHeight {
  regular = 'regular',
  tall = 'tall',
}

type UltimateBannerModuleModel = {
  bannerHeight?: UltimateBannerHeight;
  bannerLink?: LocalizableLinkEntry;
  buttons?: UltimateButtonEntry[];
  image: Asset;
  imageAltText?: string;
  mobileImage?: Asset;
  modalButtonText?: string;
  modalContent?: Entry<ISiteWideBannerModalModel>;
  oneButtonPerRow?: boolean;
  textLineOne?: UltimateTextEntry[];
  textLineThree?: UltimateTextEntry[];
  textLineTwo?: UltimateTextEntry[];
};
export type UltimateBannerModuleEntry = Entry<UltimateBannerModuleModel>;

// Ultimate Button
type UltimateButtonModel = {
  buttonTheme: ButtonThemeEnum;
  buttonType: ButtonTypeEnum;
  link: LocalizableLinkEntry;
  text: string;
};
export type UltimateButtonEntry = Entry<UltimateButtonModel>;

// Ultimate Text
type UltimateTextModel = {
  color: string;
  fontFamily: FontFamilyEnum;
  size: TextSizeEnum;
  text: string;
  weight: TextWeightEnum;
};
export type UltimateTextEntry = Entry<UltimateTextModel>;
