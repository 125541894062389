import classnames from 'classnames';
import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';

import useScrollLock from 'lib/hooks/scrollLock/useScrollLock';

import CloseIcon from 'assets/icons/ic-close.inline.svg';

import styles from './Modal.module.scss';

import { MODAL_PORTAL_CLASS_NAME } from '../constants';

const REACT_ROOT_ID = '#__next';

/**
 * When shallow rendering in tests, Next.js is not setting the id of the root element,
 * therefore we skip this step so that no error is thrown.
 */
if (process.env.TRUE_NODE_ENV !== 'test') {
  ReactModal.setAppElement(REACT_ROOT_ID);
}

export type ModalProps = {
  children: ReactNode;
  header?: string | ReactNode;
  headerClassname?: string;
  headerCloseButtonClassname?: string;
  headerTextClassname?: string;
  hideDefaultHeader?: boolean;
  isUsingWidthOverride?: boolean;
  onCloseClick(e?: MouseEvent<HTMLOrSVGElement>): void;
} & ReactModalProps;

export const MODAL_CLOSE_TIME_OUT = 300;

const Modal = (props: ModalProps) => {
  const {
    children,
    className,
    contentRef,
    header,
    headerClassname,
    headerCloseButtonClassname,
    headerTextClassname,
    hideDefaultHeader = false,
    isOpen,
    isUsingWidthOverride = false,
    onCloseClick,
    overlayClassName,
    ...rest
  } = props;

  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const [isAnimatingIn, setIsAnimatingIn] = useState(false);

  const { refCallback } = useScrollLock(isOpen);

  const handleContentRefCallback = useCallback(
    element => {
      refCallback(element);
      if (contentRef) {
        contentRef(element);
      }
    },
    [contentRef, refCallback]
  );

  useEffect(() => {
    if (!isOpen && isAnimatingIn) {
      setIsAnimatingIn(false);
    }

    if (isOpen && isAnimatingOut) {
      setIsAnimatingOut(false);
    }

    if (isOpen && !isAnimatingIn) {
      setIsAnimatingIn(true);
    }
  }, [isOpen, isAnimatingIn, isAnimatingOut]);

  const handleClose = useCallback(() => {
    setIsAnimatingOut(true);
    setIsAnimatingIn(false);

    onCloseClick();
  }, [onCloseClick]);

  return (
    <ReactModal
      className={classnames(styles.root, className, {
        [styles.animateIn]: isAnimatingIn,
        [styles.animateOut]: isAnimatingOut,
        [styles.constrainWidth]: !isUsingWidthOverride,
      })}
      closeTimeoutMS={MODAL_CLOSE_TIME_OUT}
      contentRef={handleContentRefCallback}
      isOpen={isOpen}
      onRequestClose={handleClose}
      overlayClassName={classnames(styles.overlay, overlayClassName)}
      portalClassName={MODAL_PORTAL_CLASS_NAME}
      shouldCloseOnOverlayClick
      {...rest}
    >
      {!hideDefaultHeader && (
        <div className={classnames(styles.header, headerClassname)}>
          <h2 className={classnames(styles.headerText, headerTextClassname)}>
            {header}
          </h2>
          <CloseIcon
            aria-label="Close"
            className={classnames(styles.closeIcon, headerCloseButtonClassname)}
            onClick={handleClose}
            role="button"
            tabIndex={0}
          />
        </div>
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;
