import { ReactElement } from 'react';

import { IButtonBaseProps } from './ButtonBase';
import SecondaryButton from './SecondaryButton';
import TertiaryButton from './TertiaryButton';

export type CtaButtonProps = IButtonBaseProps;

const CtaButton = (props: CtaButtonProps): ReactElement => {
  const { ...uiProps } = props;
  if (uiProps.isTertiaryButton) {
    return <TertiaryButton {...uiProps} />;
  } else {
    return <SecondaryButton {...uiProps} />;
  }
};

export default CtaButton;
