import Link from 'next/link';
import { ReactElement } from 'react';

import { pageRoutes } from 'lib/routes';

type OrderLinkProps = {
  children: ReactElement;
  orderId?: string;
  passHref?: boolean;
};

const OrderLink = ({
  children,
  orderId,
  passHref,
}: OrderLinkProps): ReactElement => {
  if (orderId) {
    const displayUrl = pageRoutes.order.detail.displayUrl(orderId);

    return (
      <Link href={displayUrl} passHref={passHref}>
        {children}
      </Link>
    );
  } else {
    const displayUrl = pageRoutes.order.list.displayUrl();

    return (
      <Link href={displayUrl} passHref={passHref} prefetch={false}>
        {children}
      </Link>
    );
  }
};

export default OrderLink;
