import {
  ErrorOrMessage,
  printToConsole,
  SEVERITY_LEVEL,
} from 'lib/utils/Logger';

export const Logger = {
  error(errorOrMessage: ErrorOrMessage, error?: Error) {
    printToConsole({ error, errorOrMessage, severity: SEVERITY_LEVEL.ERROR });
  },

  log(errorOrMessage: ErrorOrMessage, error?: Error) {
    printToConsole({ error, errorOrMessage, severity: SEVERITY_LEVEL.INFO });
  },

  warn(errorOrMessage: ErrorOrMessage, error?: Error) {
    printToConsole({ error, errorOrMessage, severity: SEVERITY_LEVEL.WARNING });
  },
};
