import { Logger } from 'test/integration/utils/Logger';

import { isBrowser } from 'lib/utils/browser';

export const VERISHOP_NAMESPACE = 'Verishop';

export enum GlobalFunctionNames {
  OpenAppBannerModal = 'openAppBannerModal',
  OpenAppDownloadInterstitial = 'openAppDownloadInterstitial',
  OpenNewsletterModal = 'openNewsletterModal',
}

export const initializeVerishopGlobalNamespace = () => {
  if (isBrowser()) {
    window[VERISHOP_NAMESPACE] = window[VERISHOP_NAMESPACE] || {}; // do not override the existing one
  }
};

export const setGlobalFunction = (
  name: GlobalFunctionNames,
  func: () => any
) => {
  if (isBrowser() && name) {
    try {
      if (!window[VERISHOP_NAMESPACE]) {
        initializeVerishopGlobalNamespace();
      }
      window[VERISHOP_NAMESPACE][name] = func;
    } catch (error) {
      Logger.warn(
        `Unable to attach {${name}} to window.${VERISHOP_NAMESPACE}`,
        error
      );
    }
  }
};

export const setOpenAppBannerModal = (func: () => any) => {
  setGlobalFunction(GlobalFunctionNames.OpenAppBannerModal, func);
};

export const setOpenAppDownloadInterstitial = (func: () => any) => {
  setGlobalFunction(GlobalFunctionNames.OpenAppDownloadInterstitial, func);
};

export const setOpenNewsletterModal = (func: () => any) => {
  setGlobalFunction(GlobalFunctionNames.OpenNewsletterModal, func);
};
