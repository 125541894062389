import { Offer } from 'schema-dts';

import { GenerateProductLinkedDataProps } from './product';
import { verishopShoppingStatusToAvailablility } from './utils';

type GenerateOfferProps = Pick<
  GenerateProductLinkedDataProps,
  'price' | 'selectedVariant' | 'shoppingStatus' | 'variantAvailability'
>;
/**
 * Generates the `Offer` schema for a particular product. This is to be used with ld+json
 * for SEO and linked data JSON purposes.
 * @reference https://schema.org/Offer
 */
export const generateOffer = ({
  price,
  selectedVariant,
  shoppingStatus,
  variantAvailability,
}: GenerateOfferProps) => {
  const offer: Offer = {
    '@type': 'Offer',
    itemCondition: 'NewCondition',
    price: price || undefined,
    priceCurrency: 'USD',
    seller: {
      '@type': 'Organization',
      name: 'Verishop',
    },
  };

  // set a fallback product availability
  const availability = verishopShoppingStatusToAvailablility(shoppingStatus);
  if (availability) {
    offer.availability = availability;
  }

  // if variant is selected, use its availability
  if (selectedVariant) {
    const variantSid = selectedVariant.sid;
    const thisVariantAvailablility = variantAvailability?.find(
      variant => variant.sid === variantSid
    );

    if (thisVariantAvailablility) {
      offer.availability = thisVariantAvailablility.inStock
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock';
    }
  }

  return offer;
};
