import { format, parseISO } from 'date-fns';
import toNumber from 'lodash/toNumber';

const LANGUAGE_CODE = 'en-US';

function formatLargeNumbers(
  largeNumber: number,
  locales: string = LANGUAGE_CODE
) {
  return largeNumber.toLocaleString(locales);
}

// Formats an amount to a proper currency string in a requested locale
function formatCurrency(
  amount: string | number,
  currencyCode: string | null = 'USD',
  languageCode: string = LANGUAGE_CODE
) {
  return new Intl.NumberFormat(languageCode, {
    currency: currencyCode ?? 'USD',
    style: 'currency',
  }).format(toNumber(amount));
}

/*
  Formats date, and defaults to "MMMM dd, yyyy".
*/
function formatDate(date: string, formatString = 'MMMM dd, yyyy') {
  const result = format(parseISO(date), formatString);
  return result === 'Invalid Date' ? null : result;
}

export default {
  formatCurrency,
  formatDate,
  formatLargeNumbers,
};
