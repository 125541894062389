import Link from 'next/link';

import { pageRoutes } from 'lib/routes';

type EditorialLinkProps = {
  children?: any;
  publishDate?: string;
  slug: string;
};

const EditorialLink = (props: EditorialLinkProps) => {
  const { publishDate, slug } = props;
  const displayUrl = pageRoutes.editorial.displayUrl({ publishDate, slug });

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {props.children}
    </Link>
  );
};

export default EditorialLink;
