import { SearchClient } from 'algoliasearch/lite';
import { createContext } from 'react';

import { AlgoliaInitialState } from '../algolia/getAlgoliaServerState';

type AlgoliaContextType = {
  algoliaClient?: SearchClient;
  initialState?: AlgoliaInitialState;
};

const AlgoliaContext = createContext<AlgoliaContextType>({});

export default AlgoliaContext;
