import { Document } from '@contentful/rich-text-types';
import to from 'await-to-js';
import { Asset, Entry } from 'contentful';

import { ImageCarouselModuleEntry } from './_modules';
import { PlpMarketingCardEntry } from './_plpMarketingCard';
import {
  client,
  ContentfulContentTypeEnum,
  UltimateBannerModuleEntry,
} from './index';

import { AlgoliaFacetListEntry } from 'types/contentful';

export type CategoryModel = {
  description?: Document;
  facetList?: AlgoliaFacetListEntry;
  footerBanner?: UltimateBannerModuleEntry;
  headerBanner?: UltimateBannerModuleEntry;
  image?: Asset;
  imageCarouselModule?: ImageCarouselModuleEntry;
  metaDescription?: string;
  metaTitle?: string;
  mobileImage?: Asset;
  pickFullWidthBanner?: Entry<unknown>;
  picks?: Entry<unknown>;
  plpMarketingCards?: PlpMarketingCardEntry[];
  slug: string;
  title: string;
};

export type CategoryEntry = Entry<CategoryModel>;

export const loadCategory = async (
  categorySlug: string
): Promise<CategoryModel | undefined> => {
  const [error, response] = await to(
    client.getEntries<CategoryModel>({
      content_type: ContentfulContentTypeEnum.category,
      'fields.slug': categorySlug,
      include: 3,
      limit: 1,
    })
  );

  if (error) {
    throw new Error(`Error retrieving category ${categorySlug}: ${error}`);
  }

  return response?.items?.[0]?.fields;
};
