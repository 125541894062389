import Link from 'next/link';
import { ReactElement } from 'react';

import { getAccountViewUrl } from 'explorer/lib/utils/profile';
import { TAB_ID } from 'explorer/types/Profile';

type AccountLinkProps = {
  children: ReactElement;
  passHref?: boolean;
};

const AccountLink = ({
  children,
  passHref,
}: AccountLinkProps): ReactElement => {
  const displayUrl = getAccountViewUrl(TAB_ID.COLLECTIONS);

  return (
    <Link href={displayUrl} passHref={passHref} prefetch={false}>
      {children}
    </Link>
  );
};

export default AccountLink;
