/**
 * This file implements https://schema.org/Person
 */

import { format, parseISO } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { JsonLd } from 'react-schemaorg';
import { Person } from 'schema-dts';

import { PersonLinkedDataModel, TastemakerModel } from 'lib/contentful';
import {
  generateCountry,
  generateEducationalOrganization,
  generateQuantitativeValue,
} from 'lib/metatags/utils';
import Logger from 'lib/utils/Logger';

type PersonLinkedDataProps = {
  person: Person;
};

export const generatePerson = (props: PersonLinkedDataModel): Person => {
  const personLinkedData: Person = {
    '@type': 'Person',
    name: props.name,
  };

  if (props.alumniOf) {
    personLinkedData.alumniOf = generateEducationalOrganization({
      name: props.alumniOf,
    });
  }
  if (props.awards) {
    personLinkedData.awards = props.awards;
  }
  if (props.birthDate) {
    personLinkedData.birthDate = format(
      parseISO(props.birthDate),
      'yyyy-MM-dd'
    );
  }
  if (props.description) {
    personLinkedData.description = props.description;
  }
  if (props.gender) {
    personLinkedData.gender = props.gender;
  }
  if (props.heightInches) {
    personLinkedData.height = generateQuantitativeValue({
      unit: 'inches',
      value: props.heightInches,
    });
  }
  if (props.honorificPrefix) {
    personLinkedData.honorificPrefix = props.honorificPrefix;
  }
  if (props.honorificSuffix) {
    personLinkedData.honorificSuffix = props.honorificSuffix;
  }
  if (props.jobTitle) {
    personLinkedData.jobTitle = props.jobTitle;
  }
  if (props.nationality) {
    personLinkedData.nationality = generateCountry({
      name: props.nationality,
    });
  }
  return personLinkedData;
};

export const convertTastemakerModelToPerson = (
  tastemaker?: TastemakerModel
): Person | null => {
  if (isEmpty(tastemaker) || !tastemaker?.handle) {
    return null;
  }
  try {
    return generatePerson(tastemaker.personLinkedData.fields);
  } catch (error) {
    Logger.warn('Unable to generate person linked data for tastemaker', error);
    return null;
  }
};

export const PersonLinkedData = ({ person }: PersonLinkedDataProps) => {
  if (isEmpty(person)) {
    return null;
  }
  try {
    return (
      <JsonLd<Person>
        item={{
          '@context': 'https://schema.org',
          ...person,
        }}
      />
    );
  } catch (error) {
    const personSlug = person?.name || '???';
    Logger.warn(`Unable to generate LinkedData for person ${personSlug}`);
    return null;
  }
};
