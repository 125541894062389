// Primary button, example: Show Now, Buy Now, Register, Login
import { ReactElement } from 'react';

import ButtonBase, { IButtonBaseProps } from './ButtonBase';

const PrimaryButton = (props: IButtonBaseProps): ReactElement => {
  return <ButtonBase {...props} isDark isPrimaryButton />;
};

export default PrimaryButton;
