import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  PLP_TYPE,
  PLPScrolledTrackingData,
  PLPViewedTrackingData,
} from './index';
import {
  CustomAttribute,
  ShopifyCart,
  ShopifyCartLineItem,
  ShopifyCheckoutLineItemEdge,
} from 'data/graphql/types.shopify';

import { minifyStringForHtml } from 'lib/formatters';
import { pageRoutes } from 'lib/routes';
import { tryConvertSidToId } from 'lib/shopify/utils';
import Logger from 'lib/utils/Logger';

import {
  PDPProductTrackingAttributes,
  PDPVariantTrackingAttributes,
} from 'data/graphql/types';
import { GetCartProductsBySids } from 'types/api';
import {
  CartViewedEventData,
  PLPFilteredEventData,
  ProductEventData,
} from 'types/segment';

import { ProductCardData } from '../../components/ProductGrid/ProductCard/types';

export const CART_LINE_ITEM_CATEGORY_NOT_FOUND = 'not avalable';
export const getShopifyCartLineItemCategory = (
  customAttributes: CustomAttribute[],
  notFoundValue: string = CART_LINE_ITEM_CATEGORY_NOT_FOUND
): string => {
  try {
    if (isEmpty(customAttributes)) {
      Logger.log(
        `Unable to get ProductVariantCategory: Custom Attributes are empty - using notFoundValue as a default: "${notFoundValue}"`
      );
      return notFoundValue;
    }

    return get(
      customAttributes.find(attribute => attribute.key === '_category'),
      'value',
      notFoundValue
    );
  } catch (error) {
    Logger.warn(
      `Unable to get Product Category information from Cart Line Item Variant, using NotFoundValue: "${notFoundValue}"`,
      error
    );
    return notFoundValue;
  }
};

export const convertCartLineItemToProductEventData = (
  cartLineItem: ShopifyCartLineItem,
  categoryFallbackValue?: string
): ProductEventData => {
  const { customAttributes, quantity, title, variant } = cartLineItem;
  const {
    compareAtPrice,
    id,
    image,
    price,
    product: { id: productSid, vendor: brand },
    sku,
  } = variant;

  const valueForAnalytics = Number.parseFloat(price);
  const discountValue =
    compareAtPrice > 0 ? compareAtPrice - valueForAnalytics : 0;
  const imageUrl = get(image, 'originalSrc');
  const category = getShopifyCartLineItemCategory(
    customAttributes,
    categoryFallbackValue
  );

  return {
    brand,
    category,
    discount: Number.parseFloat(discountValue.toFixed(2)),
    image_url: imageUrl,
    name: title,
    price: valueForAnalytics,
    product_id: tryConvertSidToId(productSid) || productSid,
    quantity,
    sku,
    url: window.location.href,
    value: valueForAnalytics,
    variant: tryConvertSidToId(id) || id,
  };
};

export const convertCartDataToCartViewedEventData = (
  cart: ShopifyCart
): CartViewedEventData => {
  const lineItems: ProductEventData[] = cart.lineItems.edges.map(
    ({ node }: ShopifyCheckoutLineItemEdge) =>
      convertCartLineItemToProductEventData(node)
  );

  return {
    cart_id: tryConvertSidToId(cart.id) || cart.id,
    products: lineItems,
  };
};

/**
 * Create tracking data for PLP page scrolled.
 */
export const formatPlpPageScrolled = (
  count: number,
  plpType: PLP_TYPE,
  filters?: string,
  query?: string,
  slug?: string,
  sort?: string
): PLPScrolledTrackingData => {
  return {
    count,
    filters,
    list_id: plpType,
    query,
    slug,
    sort,
  };
};

export const formatPDPViewedData = (
  product: PDPProductTrackingAttributes,
  variant: PDPVariantTrackingAttributes,
  url: string
): ProductEventData => {
  const { brand, family, sid, title } = product;
  const {
    galleryImages,
    inStock,
    price,
    shoppingStatus,
    sid: variantSid,
    sku,
  } = variant;

  const imageUrl = get(galleryImages, '[0].url');

  return {
    brand,
    category: family,
    image_url: imageUrl,
    in_stock: inStock,
    name: title,
    price,
    product_id: sid,
    quantity: 1,
    shoppingStatus,
    sku,
    url,
    value: price,
    variant: variantSid,
  };
};

// Returns the category, with an optional name for a tastemaker.
export const formatTasteMakerPLPViewed = (
  slug?: string
): PLPViewedTrackingData => {
  return { list_id: PLP_TYPE.TASTEMAKERS, slug };
};

export const extractTrackingDataFromCheckoutByVariantId = (
  variantSid: string,
  checkout: ShopifyCart,
  category: string
): ProductEventData | undefined => {
  try {
    const cartLineItem = checkout?.lineItems?.edges?.find(
      ({ node: { variant } }) => variant.id === variantSid
    );
    if (cartLineItem) {
      return convertCartLineItemToProductEventData(cartLineItem.node, category);
    }
  } catch (error) {
    Logger.error(
      'Unable to extract Tracking ProductData from checkout by VariantId',
      error
    );
  }
};

export const convertCartProductsForSegment = (
  productVariant: GetCartProductsBySids,
  cartId: string,
  quantity: number
):
  | undefined
  | {
      brand: string;
      cart_id: string;
      category: string;
      discount: number;
      image_url?: string;
      name: string;
      price: number;
      product_id: string;
      quantity: number;
      sku: string;
      url: string;
      variant: string;
    } => {
  try {
    const {
      discount,
      galleryImages,
      price,
      product: {
        brand,
        brandSlug,
        family,
        familySlug,
        sid: productSid,
        slug: productSlug,
        title,
      },
      sid: variantSid,
      sku,
    } = productVariant;

    const productUrl = pageRoutes.productDetail.displayUrl({
      brandSlug,
      familySlug,
      productSid,
      productSlug,
    });

    return {
      brand,
      cart_id: cartId,
      category: family,
      discount,
      image_url: get(galleryImages, '[0].url'),
      name: title,
      price,
      product_id: productSid,
      quantity,
      sku,
      url: productUrl.pathname,
      variant: variantSid,
    };
  } catch (error) {
    Logger.error(
      'Analytics: Something went wrong converting productVariant data for add to cart',
      error
    );
  }
};

export const formatProductListFilteredData = (
  filterRefinements: {
    [key: string]: string[] | string;
  },
  hits: ProductCardData[],
  sortString: string,
  categoryType: PLP_TYPE,
  categoryName?: string,
  nbHits?: number
): PLPFilteredEventData | undefined => {
  try {
    const category = `${categoryType}${
      !!categoryName ? `/${categoryName}` : ''
    }`;

    const sortArray = [{ type: 'AlgoliaIndex', value: sortString }];
    const filterArray = [];

    for (const filterType of Object.keys(filterRefinements)) {
      const filterValue = filterRefinements[filterType];

      if (typeof filterValue === 'string') {
        filterArray.push({ type: filterType, value: filterValue });
      } else {
        filterArray.push({
          type: filterType,
          value: JSON.stringify(filterRefinements[filterType]),
        });
      }
    }

    const products: ProductEventData[] = hits.map(
      (searchResult: ProductCardData, index: number) => {
        const {
          brand,
          brandSlug,
          family,
          familySlug,
          productSid,
          productSlug: slug,
          title,
        } = searchResult;

        const productUrl = pageRoutes.productDetail.displayUrl({
          brandSlug,
          familySlug,
          productSid,
          productSlug: slug,
        });

        return {
          brand,
          category: family,
          name: title,
          position: index,
          product_id: productSid,
          url: productUrl.pathname,
        };
      }
    );

    return {
      category,
      filters: filterArray,
      nbHits,
      products,
      sorts: sortArray,
    };
  } catch (error) {
    Logger.error(
      'Analytics: Formatting data for Filtering content error',
      error
    );
  }
};

export const mockAnalytics = minifyStringForHtml(`
window.analytics = {
  identify: function() {},
  page: function() {},
  ready: function() {},
  reset: function() {},
  track: function() {},
  user: function() {
    return {
      anonymousId: function() {}
    };
  }
};
window.heap = {
  resetIdentify: function() {}
};
`);
