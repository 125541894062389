import Link from 'next/link';
import { ReactNode } from 'react';

import { pageRoutes } from 'lib/routes';

type DynamicPageLinkProps = {
  children?: ReactNode;
  pageSlug: string;
};

const DynamicPageLink = (props: DynamicPageLinkProps) => {
  const { pageSlug } = props;
  const displayUrl = pageRoutes.dynamicPage.displayUrl(pageSlug);

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {props.children}
    </Link>
  );
};

export default DynamicPageLink;
