import querystring from 'querystring';

import { Breakpoints } from 'lib/breakpoints';
import {
  handleProtocolRelativeUrl,
  isContentfulUrl,
} from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

export const cloudinaryFetchBaseUrl =
  'https://assets3.verishop.com/image/fetch';

export const DEFAULT_HEIGHT_ASPECT_RATIO = 4 / 3;
export const DEFAULT_WIDTH_ASPECT_RATIO = 3 / 4;

// Don't crop photos for the Electronics category
// Note that Crop has similar effect as object-fit:cover except crop / entropy may provide better cropping
const EXCLUDE_CROP_CATEGORIES = ['Electronics & Tech Accessories'];

// Only trim photos for specified categories
const SHOULD_TRIM_CATEGORIES: string[] = [];

const IMAGE_SRC_HANDLER_DOMAIN = 'https://images-src.verishop.com'; // currently, CF worker only exists in production

type ResizeImageProps = {
  blur?: number;
  categories?: string[];
  crop?: string;
  dpr?: number;
  fit?: string;
  focus?: string;
  height?: number;
  quality?: number;
  url: string;
  useCloudinaryProgressive?: boolean;
  width?: number;
};

type ResponsiveImageUrls = {
  '1x': string;
  '2x': string;
  '360w_1x'?: string;
  '360w_2x'?: string;
  '768w_1x'?: string;
  '768w_2x'?: string;
};

export type ImageSrcAttributes = {
  src: string;
  srcSet: string;
};

export type GenerateImageSrcAttributesProps = {
  blur?: number;
  categories?: string[];
  height?: number;
  limitToDPR1?: boolean;
  quality?: number;
  url: string;
  useCloudinaryProgressive?: boolean;
  useSizeBasedProgressive?: boolean;
  width?: number;
};

/**
 * Generate `srcSet` attributes for an <img /> element
 */
export const generateImageSrcSet = (
  imageAttributes: GenerateImageSrcAttributesProps
): string => {
  if (imageAttributes.limitToDPR1) {
    return generateImageSrcAttributes(imageAttributes).src;
  }

  return generateImageSrcAttributes(imageAttributes).srcSet;
};

/**
 * Generate `src` and `srcSet` attributes for an <img /> element
 */
export const generateImageSrcAttributes = (
  imageOptions: GenerateImageSrcAttributesProps
): ImageSrcAttributes => {
  if (
    !imageOptions.height &&
    !imageOptions.width &&
    !imageOptions.useSizeBasedProgressive
  ) {
    Logger.warnConsole('Image resize specified without a width or height');
  }

  const imageUrls = generateResponsiveImageUrls(imageOptions);

  if (
    imageOptions.useSizeBasedProgressive &&
    !imageOptions.useCloudinaryProgressive
  ) {
    return {
      // return 1x image for devices that don't support retina
      src: imageUrls['768w_1x'] || imageUrls['1x'],
      srcSet: generateResponsiveImageSrcSet(imageUrls, true),
    };
  }

  return {
    // return 1x image for devices that don't support retina
    src: imageUrls['1x'],
    srcSet: generateResponsiveImageSrcSet(imageUrls),
  };
};

export const generateResponsiveImageSrcSet = (
  imageUrls: ResponsiveImageUrls,
  generateBySize?: boolean // default to generating by DPR
): string => {
  if (generateBySize) {
    return `${imageUrls['360w_1x']} ${Breakpoints.widthExtraSmall}w, ${imageUrls['360w_2x']} 720w, ${imageUrls['768w_1x']} ${Breakpoints.widthSmall}w, ${imageUrls['768w_2x']} 1536w,`;
  }
  return `${imageUrls['1x']} 1x, ${imageUrls['2x']} 2x`;
};

export const generateResponsiveImageUrls = (
  props: ResizeImageProps
): ResponsiveImageUrls =>
  props.useCloudinaryProgressive
    ? {
        '1x': resizeImageCloudinary(props),
        '2x': resizeImageCloudinary({ ...props, dpr: 2 }),
      }
    : {
        '1x': resizeImage(props),
        '2x': resizeImage({ ...props, dpr: 2 }),
        '360w_1x': resizeImage({ ...props, width: 360 }),
        '360w_2x': resizeImage({ ...props, dpr: 2, width: 360 }),
        '768w_1x': resizeImage({ ...props, width: 768 }),
        '768w_2x': resizeImage({ ...props, dpr: 2, width: 768 }),
      };

type ResizeImageCloudinaryProps = {
  dpr?: number;
  // When used with cropping modes that crop out part of an image, the gravity qualifier specifies which part
  // of the original image to keep when one or both of the requested dimensions is smaller than the original.
  // See: https://cloudinary.com/documentation/resizing_and_cropping#control_gravity
  gravity?: 'center' | 'face';
  height?: number;
  url: string;
  width?: number;
  zoom?: number;
};

export const generateThumbUrl = ({
  gravity = 'face',
  height,
  url,
  width,
  zoom,
}: ResizeImageCloudinaryProps): string => {
  // thumbnail filter with double dpr
  const transformationOptions = ['c_thumb', 'dpr_2.0'];

  if (gravity) {
    transformationOptions.push(`g_${gravity}`);
  }

  if (width) {
    transformationOptions.push(`w_${width}`);
  }

  if (height) {
    transformationOptions.push(`h_${height}`);
  }

  if (zoom) {
    transformationOptions.push(`z_${zoom}`);
  }

  return `${cloudinaryFetchBaseUrl}/${transformationOptions.join(',')}/${url}`;
};

export const resizeImageCloudinary = ({
  dpr,
  height,
  url: imageUrl,
  width,
}: ResizeImageCloudinaryProps): string => {
  const transformationOptions = ['fl_progressive:semi'];
  if (width) {
    transformationOptions.push(`w_${width}`);
  }

  if (height) {
    transformationOptions.push(`h_${height}`);
  }

  if (dpr) {
    transformationOptions.push(`dpr_${dpr}`);
  }

  return transformationOptions.length
    ? `${cloudinaryFetchBaseUrl}/${transformationOptions.join(',')}/${imageUrl}`
    : `${cloudinaryFetchBaseUrl}/${imageUrl}`;
};

type ImageTransformationOptions = {
  auto: string;
  blur?: number;
  crop?: string;
  cs?: string;
  dpr?: number;
  f?: string;
  fillcolor?: string;
  fit: string;
  h?: number;
  q?: number;
  src?: string;
  trim?: string;
  'trim-md'?: number;
  'trim-sd'?: number;
  w?: number;
};

export const resizeImage = ({
  blur,
  categories,
  crop,
  dpr,
  fit,
  focus,
  height,
  quality,
  url,
  width,
}: ResizeImageProps): string => {
  // more options: https://docs.imgix.com/setup/serving-images#resizing-and-cropping
  const imageTransformationOptions: ImageTransformationOptions = {
    auto: 'format',
    // remove color space metadata from image for file size reduction
    // [https://docs.imgix.com/apis/url/format/cs]
    cs: 'strip',
    // resizes without cropping, but will not increase the size of the image
    // [https://docs.imgix.com/apis/url/size/fit#max]
    fit: 'max',
  };

  // Reset the default parameters if width and height are both set
  if (width && width > 0 && height && height > 0) {
    const shouldNotCrop = EXCLUDE_CROP_CATEGORIES.some(v =>
      (categories ?? []).includes(v)
    );
    if (shouldNotCrop) {
      // Keep the original content and fill the white background
      // which prevents the object-fit:cover to crop again
      imageTransformationOptions.fit = 'fillmax';
      imageTransformationOptions.fillcolor = 'FFFFFF';
    } else {
      // Resizes the image to fill the width and height dimensions and crops any excess image data.
      // [https://docs.imgix.com/apis/url/size/fit#crop]
      imageTransformationOptions.fit = 'crop';
      imageTransformationOptions.crop = 'entropy';
    }

    const shouldTrim = SHOULD_TRIM_CATEGORIES.some(v =>
      (categories ?? []).includes(v)
    );
    if (shouldTrim) {
      // remove uniform border colors from your image, effectively cropping it
      // [https://docs.imgix.com/apis/rendering/trim]
      imageTransformationOptions.trim = 'auto';
      // the mean difference threshold for the trimming operation
      imageTransformationOptions['trim-md'] = 8;
      // the standard deviation among pixels within a border
      imageTransformationOptions['trim-sd'] = 2;
    }
  }

  if (!url) {
    return '';
  }
  if (width) {
    imageTransformationOptions.w = width;
  }
  if (height) {
    imageTransformationOptions.h = height;
  }
  if (fit) {
    imageTransformationOptions.fit = fit;
  }
  if (crop) {
    imageTransformationOptions.crop = crop;
  }
  if (dpr) {
    imageTransformationOptions.dpr = dpr;
  }
  if (quality) {
    imageTransformationOptions.q = quality;
  }
  if (blur) {
    imageTransformationOptions.blur = blur;
  }
  if (focus) {
    imageTransformationOptions.f = focus;
  }

  let imageServiceOverrideUrl = '';

  if (isContentfulUrl(url)) {
    const imageUrl = handleProtocolRelativeUrl(url);
    imageTransformationOptions.src = encodeURI(imageUrl);
    imageServiceOverrideUrl = IMAGE_SRC_HANDLER_DOMAIN;
  } else {
    imageServiceOverrideUrl = url.replace(
      '//assets.verishop.',
      '//images.verishop.'
    );
  }

  return `${imageServiceOverrideUrl}?${querystring.stringify(
    imageTransformationOptions
  )}`;
};
