import { GridScreenSize } from 'components/Grid/setup';

// number of columns a product card spans for each screen size
export const CARD_COLUMNS_BY_SCREEN_SIZE = {
  [GridScreenSize.XSmall]: 6,
  [GridScreenSize.Small]: 4,
  [GridScreenSize.Medium]: 4,
  [GridScreenSize.Large]: 3,
  [GridScreenSize.XLarge]: 3,
};
