import Link from 'next/link';
import { ReactElement } from 'react';

import { getProfileUrlByProfile } from 'explorer/lib/utils/profile';
import { TAB_ID } from 'explorer/types/Profile';

import { pageRoutes } from 'lib/routes';

import { Profile } from 'types/generated/api';

type BookmarksHomeLinkProps = {
  children: ReactElement;
  collectionProfile?: Profile;
};

const BookmarksHomeLink = (props: BookmarksHomeLinkProps) => {
  const { collectionProfile } = props;
  const displayUrl = collectionProfile
    ? getProfileUrlByProfile(collectionProfile, TAB_ID.COLLECTIONS)
    : pageRoutes.account.profile.tab.displayUrl(TAB_ID.COLLECTIONS);
  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {props.children}
    </Link>
  );
};

export default BookmarksHomeLink;
