import compact from 'lodash/compact';
import first from 'lodash/first';
import pick from 'lodash/pick';
import values from 'lodash/values';
import {
  Country,
  EducationalOrganization,
  ItemAvailability,
  Organization,
  QuantitativeValue,
} from 'schema-dts';

import { ShoppingStatus } from 'data/graphql/types';
import { HierarchicalCategories } from 'types/api';

export const verishopShoppingStatusToAvailablility = (
  shoppingStatus?: ShoppingStatus | null
): ItemAvailability | undefined => {
  switch (shoppingStatus) {
    case ShoppingStatus.ACCEPT_PREORDERS:
      return 'PreOrder';
    case ShoppingStatus.DISCONTINUED:
      return 'Discontinued';
    case ShoppingStatus.SELLABLE:
      return 'InStock';
    case ShoppingStatus.UNAVAILABLE:
      return 'OutOfStock';
    default:
      return undefined;
  }
};

/**
 * Picks the most specific category in the hierarchy
 */
export const getCategoryString = (
  hierarchicalCategories?: HierarchicalCategories
): string | undefined => {
  if (!hierarchicalCategories) {
    return;
  }
  return first(
    compact(
      values(
        pick(hierarchicalCategories, ['lvl3', 'lvl2', 'lvl1', 'lvl0'])
      ).map(first)
    )
  );
};

type CountryProps = {
  name: string;
};

export const generateCountry = ({ name }: CountryProps): Country => ({
  '@type': 'Country',
  name,
});

type OrganizationProps = {
  name: string;
};

export const generateEducationalOrganization = ({
  name,
}: OrganizationProps): EducationalOrganization => ({
  '@type': 'EducationalOrganization',
  name,
});

export const generateOrganization = ({
  name,
}: OrganizationProps): Organization => ({
  '@type': 'Organization',
  name,
});

type QuantitativeValueProps = {
  unit: string;
  value: number | string;
};

export const generateQuantitativeValue = ({
  unit,
  value,
}: QuantitativeValueProps): QuantitativeValue => ({
  '@type': 'QuantitativeValue',
  unitText: unit,
  value,
});
