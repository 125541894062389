import last from 'lodash/last';

import Logger from 'lib/utils/Logger';

export const LOCAL_CART_ID_STORAGE_KEY = 'checkoutId';

/*
  Tries to convert Shopify ID to alphanumeric ID,
  Example input: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzE3MjEzNTU1MDE2Mzk=
  Example output: 1721355501639

  When conversion fails for any reason, it will return undefined;
*/
export const tryConvertSidToId = (sid: string) => {
  // expected input sid should look something like: "gid://shopify/Product/1721355501639"
  try {
    if (!sid.includes('gid://shopify/')) {
      throw Error(`unexpected input: ${sid}`);
    }

    const productIdSplit = sid.split('/');

    // return something like 1721355501639
    return last(productIdSplit);
  } catch (error) {
    Logger.error('Unadle to convert Sid to Id', error);
    return undefined;
  }
};

export const getLocalCartId = (): string | null => {
  try {
    return localStorage.getItem(LOCAL_CART_ID_STORAGE_KEY);
  } catch (error) {
    // not available locally, which is expected in many cases, do not call Logger
    return null;
  }
};

export const setLocalCartId = (cartId?: string | null): boolean => {
  try {
    if (!cartId) {
      localStorage.removeItem(LOCAL_CART_ID_STORAGE_KEY);
    } else {
      localStorage.setItem(LOCAL_CART_ID_STORAGE_KEY, cartId);
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const isCartIdAvailable = (): boolean => {
  return !!getLocalCartId();
};
