import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import getConfig from 'config/config';

Bugsnag.start({
  apiKey: getConfig('bugsnag.apiKey'),
  appVersion: getConfig('appVersion'),
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.TRUE_NODE_ENV,
});

export default Bugsnag;
