import Logger from 'lib/utils/Logger';

export type FormValidateErrorProps = {
  code: string;
  message: string;
  name: string;
};

export const VALIDATE_ERRORS = [
  {
    code: 'UserLambdaValidationException',
    message: 'The email you entered isn’t valid.',
  },
  {
    code: 'UsernameExistsException',
    message:
      'Stylish minds think alike! An account with that email already exists.',
  },
  {
    code: 'InvalidParameterException',
    message:
      'Uh oh! Please delete any extra spaces in order to sign up for an account.',
  },
];

export const defaultValidationError = {
  code: 'DefaultError',
  message:
    'It’s not you – it’s us. Something went wrong while validating your info. Please try again.',
  name: 'DefaultError',
};

export const handleErrors = (formError: any) => {
  try {
    return {
      ...formError,
      ...VALIDATE_ERRORS.find(
        validateError => validateError.code === formError.code
      ),
    };
  } catch (error) {
    Logger.error('Error handling preLogIn / SignUp Errors', error);
    return defaultValidationError;
  }
};
