import { gql } from '@apollo/client';

export const CREATE_MULTIPASS_URL = gql`
  mutation createMultipassUrl($input: CreateMultipassUrlInput!) {
    createMultipassUrl(input: $input) {
      multipassUrl
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

export type CreateMultipassUrlVariables = {
  input: {
    returnUrlPath: string;
  };
};

export type CreateMultipassUrlResponse = {
  createMultipassUrl: {
    multipassUrl: string;
    success: boolean;
    validationErrors: Array<{
      field: string;
      message: string;
    }>;
  };
};
