import { client } from './index';

const FOOTER_ENTRY_ID = 'hSd0NqC5IPq06nDiq9UnZ';

export enum FooterLinkTypes {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
  SOCIAL_FACEBOOK = 'social_facebook',
  SOCIAL_INSTAGRAM = 'social_instagram',
  SOCIAL_PINTEREST = 'social_pinterest',
  SOCIAL_SNAPCHAT = 'social_snapchat',
  SOCIAL_TWITTER = 'social_twitter',
  SOCIAL_YOUTUBE = 'social_youtube',
}

export interface IFooterLinkModel {
  displayText: string;
  imageSrc?: string;
  path: string;
  rel?: string;
  target: string;
  type?: FooterLinkTypes;
}

export interface IFooterSectionModel {
  items: IFooterLinkModel[];
  title: string;
}

export interface IFooterColumnModel {
  position: number;
  sections: IFooterSectionModel[];
}

export interface IFooterModel {
  bottomLinks: IFooterLinkModel[];
  columns: IFooterColumnModel[];
}

export const loadFooterData = async () => {
  return await client.getEntry<{ data: IFooterModel }>(FOOTER_ENTRY_ID);
};
