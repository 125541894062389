import Link from 'next/link';
import { ReactElement } from 'react';

import { pageRoutes } from 'lib/routes';

type CollectionLinkProps = {
  children?: ReactElement;
  collectionSlug: string;
  queryParams?: unknown;
};

const CollectionLink = (props: CollectionLinkProps) => {
  const { collectionSlug, queryParams } = props;
  const displayUrl = pageRoutes.collection.displayUrl(
    collectionSlug,
    queryParams
  );

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {props.children}
    </Link>
  );
};

export default CollectionLink;
