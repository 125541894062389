import { EVENT_SOURCE } from './index';

import { CompactedExperiments } from '../experiment/experimentConfig';
import { isBrowser } from '../utils/browser';
import Logger from '../utils/Logger';
import { IS_ANALYTICS_ENABLED } from '../utils/tracking';

type UserTraits = {
  anonymousId?: string;
  experiments?: CompactedExperiments;
};

enum EventMethod {
  IDENTIFY_ANONYMOUS_USER_TO_ANALYTICS = 'identifyAnonymousUserToAnalytics',
  IDENTIFY_USER = 'identifyUser',
}

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
const safeIdentify = (
  eventMethod: EventMethod,
  anonymousId: string,
  traits?: Record<string, CompactedExperiments | string | undefined | null>,
  userId?: string
) => {
  if (!isBrowser()) {
    // Server side. Just skip.
    return;
  }
  if (!IS_ANALYTICS_ENABLED) {
    Logger.warnConsole(
      `identifyEvent(${eventMethod}, ${anonymousId}, ${userId}, ${JSON.stringify(
        traits
      )})`
    );
    return;
  }
  try {
    // Unfortunately segment uses two methods w/ and w/o userId
    const args = [
      ...(userId ? [userId] : []),
      {
        ...traits,
        eventMethod,
        eventSource: EVENT_SOURCE.WEB,
      },
      { anonymousId }, // prevents the anonymousId from being reset by Segment
    ];
    window.analytics.identify(...args);
  } catch (error) {
    Logger.error(
      `Uncaught error calling analytics identify(${eventMethod}, ${userId}, ${anonymousId}, '${JSON.stringify(
        traits
      )}')`,
      error
    );
  }
};

export const identifyUser = (
  traits?: UserTraits,
  anonymousId?: string | null,
  authContext?: {
    email: string;
    userId: string;
  }
) => {
  if (authContext) {
    safeIdentify(
      EventMethod.IDENTIFY_USER,
      anonymousId || '',
      {
        ...traits,
        anonymousId,
        email: authContext.email,
      },
      authContext.userId
    );
  } else if (anonymousId) {
    safeIdentify(
      EventMethod.IDENTIFY_ANONYMOUS_USER_TO_ANALYTICS,
      anonymousId,
      traits
    );
  }
};
