import Link from 'next/link';

import { pageRoutes } from 'lib/routes';

type ForgotPasswordLinkProps = {
  children: any;
};

const ForgotPasswordLink = (props: ForgotPasswordLinkProps) => {
  const route = pageRoutes.auth.forgotPassword;
  const displayUrl = route.displayUrl();

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {props.children}
    </Link>
  );
};

export default ForgotPasswordLink;
