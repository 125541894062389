import { gql } from '@apollo/client';

export const GET_EXPERIMENTS = gql`
  query getExperiments(
    $id: String!
    $idType: ExperimentIdType!
    $namespace: String!
  ) {
    experiment(id: $id, idType: $idType, namespace: $namespace) {
      experiments {
        bucket
        name
        values {
          key
          value
        }
        version
      }
      lastUpdatedTs
    }
  }
`;
