import Link from 'next/link';
import { ReactElement } from 'react';

import { getProfileUrlByProfile } from 'explorer/lib/utils/profile';

import { Profile } from 'types/generated/api';

type ProfileLinkProps = {
  children?: ReactElement;
  profile: Profile;
};

const ProfileLink = (props: ProfileLinkProps) => {
  const { children, profile } = props;
  const displayUrl = getProfileUrlByProfile(profile);

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {children}
    </Link>
  );
};

export default ProfileLink;
