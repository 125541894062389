// Button base component. Has functionality shared by all buttons
import classnames from 'classnames';
import { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react';

import SpinnerInline from 'components/Spinner/SpinnerInline/SpinnerInline';
import SuccessIndicator from 'components/SuccessIndicator/SuccessIndicator';

import styles from './ButtonBase.module.scss';

export interface IButtonBaseProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  'data-test-id'?: string;
  disabled?: boolean;
  isDark?: boolean;
  isError?: boolean;
  isIconButton?: boolean;
  isLoading?: boolean;
  isPrimaryButton?: boolean;
  isSecondaryButton?: boolean;
  isSuccess?: boolean;
  isTertiaryButton?: boolean;
  isTextButton?: boolean;
  size?: BUTTON_SIZE;
  successText?: string;
  text?: string;
}

export enum BUTTON_SIZE {
  AUTO = 'auto',
  LARGE = 'isLarge',
  MEDIUM = 'isMedium',
  SMALL = 'isSmall',
  TINY = 'isTiny',
}

const ButtonBase = (props: IButtonBaseProps): ReactElement => {
  const {
    children,
    disabled,
    isDark,
    isIconButton = false,
    isLoading,
    isPrimaryButton = false,
    isSecondaryButton = false,
    isSuccess,
    isTertiaryButton = false,
    isTextButton = false,
    size = BUTTON_SIZE.LARGE,
    text,
    // prettier-ignore
    successText = text,
    ...rest
  } = props;

  const buttonText = isSuccess ? successText : text;

  const getSizeClassName = () => {
    switch (size) {
      case BUTTON_SIZE.TINY:
        return styles.tinyButton;
      case BUTTON_SIZE.SMALL:
        return styles.smallButton;
      case BUTTON_SIZE.MEDIUM:
        return styles.mediumButton;
      case BUTTON_SIZE.LARGE:
        return styles.largeButton;
      default:
        return styles.autoSizeButton;
    }
  };

  return (
    <button
      {...rest}
      className={classnames(
        styles.buttonBase,
        {
          [styles.primaryButton]: isPrimaryButton,
          [styles.secondaryButton]: isSecondaryButton,
          [styles.tertiaryButton]: isTertiaryButton,
          [styles.textButton]: isTextButton,
          [styles.iconButton]: isIconButton,
        },
        getSizeClassName(),
        props.className
      )}
      disabled={disabled || isLoading}
    >
      {isSuccess && !isLoading && (
        <SuccessIndicator
          className={classnames({
            [styles.noPadding]: !text && !children,
            [styles.buttonPadding]: text || children,
          })}
          isWhiteCheckmark={!isDark}
          size={size}
        />
      )}
      {isLoading ? (
        <SpinnerInline isDark={isDark} size={size} />
      ) : (
        buttonText || children
      )}
    </button>
  );
};

export default ButtonBase;
