import { KeyboardEvent } from 'react';

function keyPressHandler(keyMap: {
  [key: string]: ((event?: KeyboardEvent) => void) | undefined;
}) {
  return (event: KeyboardEvent) => {
    const callback = keyMap[event.key];

    if (callback) {
      callback(event);
    }
  };
}

export default keyPressHandler;
