import classnames from 'classnames';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import DefaultComponentProps from 'components/defaultComponentProps';

import CheckmarkIcon from 'assets/icons/ic-checkmark.inline.svg';

import styles from './SuccessIndicator.module.scss';

type SuccessIndicatorProps = {
  isWhiteCheckmark?: boolean;
  size?: BUTTON_SIZE;
} & DefaultComponentProps;

const SuccessIndicator = ({
  className,
  isWhiteCheckmark,
  size = BUTTON_SIZE.LARGE,
}: SuccessIndicatorProps) => (
  <div
    className={classnames(styles.root, className, {
      [styles.whiteCheckmark]: isWhiteCheckmark,
      [styles.mediumSize]: size === BUTTON_SIZE.MEDIUM,
      [styles.smallSize]: size === BUTTON_SIZE.SMALL,
    })}
  >
    <CheckmarkIcon className={styles.icon} />
  </div>
);

export default SuccessIndicator;
