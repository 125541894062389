import Link from 'next/link';

import { pageRoutes } from 'lib/routes';

type HomeLinkProps = {
  children: any;
  passHref?: boolean;
};

const HomeLink = (props: HomeLinkProps) => {
  const displayUrl = pageRoutes.home.displayUrl();

  return (
    <Link href={displayUrl} passHref={props.passHref} prefetch={false}>
      {props.children}
    </Link>
  );
};

export default HomeLink;
