import Link from 'next/link';
import { ReactNode } from 'react';

import { pageRoutes } from 'lib/routes';

type CartLinkProps = {
  children: ReactNode;
  passHref?: boolean;
};

const CartLink = (props: CartLinkProps) => {
  const displayUrl = pageRoutes.cart.displayUrl();

  return (
    <Link
      href={displayUrl}
      passHref={props.passHref}
      prefetch={false}
      rel="nofollow"
    >
      {props.children}
    </Link>
  );
};

export default CartLink;
