import isEmpty from 'lodash/isEmpty';
import { AggregateRating, Review } from 'schema-dts';

import { generatePerson } from './person';
import { generateProductLinkedData } from './product';

import { PersonLinkedDataModel } from 'lib/contentful';

import { AssetFkaImage } from 'types/app';
import { ReviewV2 } from 'types/generated/api';

type GenerateAggregateRatingProps = {
  ratingValue?: number;
  reviewCount?: number;
};

export const generateAggregateRating = ({
  ratingValue,
  reviewCount,
}: GenerateAggregateRatingProps): AggregateRating | null => {
  // reviewCount and ratingValue must be positive numbers
  if (!reviewCount || !ratingValue) {
    return null;
  }
  return {
    '@type': 'AggregateRating',
    ratingValue,
    reviewCount,
  };
};

export function generateFirstReview(reviews?: ReviewV2[]): Review | null {
  if (!reviews || isEmpty(reviews)) {
    return null;
  }

  // the item being reviewed has to be in stock and the reviewer has to be a verified buyer
  const firstVerifiedInStockReview = reviews.find(
    review =>
      review.verifiedBuyer && review.product?.variants?.some(v => !!v.inStock)
  );

  if (!firstVerifiedInStockReview) {
    return null;
  }

  const personLinkedDataModel: PersonLinkedDataModel = {
    name: firstVerifiedInStockReview?.user?.displayName ?? '',
  };
  const author = generatePerson(personLinkedDataModel);

  const reviewLinkedData: Review = {
    '@type': 'Review',
    author,
    dateCreated: firstVerifiedInStockReview.reviewDate,
    headline: firstVerifiedInStockReview.title ?? undefined,
    identifier: firstVerifiedInStockReview.id,
    reviewBody: firstVerifiedInStockReview.content ?? undefined,
    reviewRating: {
      '@type': 'Rating',
      bestRating: 5,
      ratingValue: firstVerifiedInStockReview.score,
    },
  };

  const product = firstVerifiedInStockReview.product;
  if (product) {
    const variant = product.variants?.find(v => v.inStock);
    const productLinkedData = generateProductLinkedData({
      brand: product.brand || product.brandSlug || product.title,
      description: product.description || product.title,
      galleryAssets: (variant?.galleryAssets || []) as AssetFkaImage[],
      hierarchicalCategories: product.hierarchicalCategories,
      name: product.title,
      price: variant?.price ?? undefined,
    });
    if (productLinkedData) {
      reviewLinkedData.itemReviewed = productLinkedData;
    }
  } else {
    // fallback to review title as itemReviewed.
    reviewLinkedData.itemReviewed =
      firstVerifiedInStockReview.title || 'product';
  }
  return reviewLinkedData;
}
