import { gql } from '@apollo/client';

export const CREATE_ONBOARDING_DISCOUNT_MUTATION = gql`
  mutation webCreateOnboardingDiscount($input: CreateOnboardingDiscountInput!) {
    createOnboardingDiscount(input: $input) {
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;
