import { makeVar } from '@apollo/client';

import { typeNames } from 'data/graphql/localState/typeNames';
import {
  ProductDetailStaticInfo,
  ProductDetailState,
  Referral,
} from 'data/graphql/types';

// Reactive Variables
export const referralVar = makeVar<Referral>({
  __typename: typeNames.Referral,
});

export const productDetailStaticInfoVar = makeVar<ProductDetailStaticInfo>({});

export const productDetailStateVar = makeVar<ProductDetailState>({});
