const EMAIL_REGEX = /.+\@.+\..+/;

export const emailValidator = (email?: string) => {
  if (!email) {
    return 'The email field is required';
  }

  if (!EMAIL_REGEX.test(email)) {
    return 'Please provide a valid email address';
  }
};

export const passwordValidator = (password?: string) => {
  if (!password) {
    return 'The password field is required';
  }

  if (password.length < 6) {
    return 'Your password must be at least 6 characters long';
  }
};

/**
 * The password validations for login may be different than that of signup.
 * For example, if in the future we decide to have stricter requirements for emails, we would still want
 * exising emails to validate event though they do not meet the same requirements.
 */
export const loginPasswordValidator = (password?: string) => {
  if (!password) {
    return 'The password field is required';
  }

  if (password.length < 6) {
    return 'Your password must be at least 6 characters long';
  }
};
