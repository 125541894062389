import { ExperimentValueType } from './experimentConfig';

import Logger from 'lib/utils/Logger';

import { ExperimentValue, Maybe } from 'types/generated/api';

export type ExperimentValues = Maybe<Array<Maybe<ExperimentValue>>>;

export class ExperimentValueResolver<T extends ExperimentValueType> {
  protected readonly defaultValue: T;
  protected readonly experimentName: string;
  protected readonly rawValues?: ExperimentValues;

  constructor(
    defaultValue: T,
    experimentName: string,
    rawValues?: ExperimentValues
  ) {
    this.defaultValue = defaultValue;
    this.experimentName = experimentName;
    this.rawValues = rawValues;
  }

  value(): T {
    if (
      !this.rawValues ||
      (this.rawValues.length ?? 0) === 0 ||
      !this.rawValues[0]
    ) {
      return this.defaultValue;
    }
    try {
      if (this.rawValues.length > 1) {
        Logger.log(
          `Redundant experiment values ${JSON.stringify(this.rawValues)} for ${
            this.experimentName
          }`
        );
      }
      return this.parseScalarValue(this.rawValues[0].value);
    } catch (e) {
      Logger.error(`parseScalarValue(${this.rawValues[0].value}) error`, e);
      return this.defaultValue;
    }
  }

  protected parseScalarValue(scalarString: string): T {
    throw new Error(`Scalar value parser for ${scalarString} not defined.`);
  }
}
