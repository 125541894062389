import find from 'lodash/find';
import last from 'lodash/last';

import { resizeImage } from 'lib/image';

export const CLOUDINARY_VIDEO_BASE_URL =
  'https://assets3.verishop.com/video/upload';

const cloudinaryVideoUrlVersion = 'v1';
const cloudinaryVideoQuality = 'q_auto';

type VideoData = {
  extension: string;
  mimeType: string;
  url: string;
};

type VideoPlayerMedia = {
  posterImage: string;
  videos: VideoData[];
};

enum VideoFileExtensionEnum {
  MP4 = 'mp4',
  OGV = 'ogv',
  WEBM = 'webm',
}

const baseVideoData: VideoData[] = [
  {
    extension: VideoFileExtensionEnum.MP4,
    mimeType: 'video/mp4',
    url: '',
  },
  {
    extension: VideoFileExtensionEnum.OGV,
    mimeType: 'video/ogg',
    url: '',
  },
  {
    extension: VideoFileExtensionEnum.WEBM,
    mimeType: 'video/webm',
    url: '',
  },
];

export const CLOUDINARY_VIDEO_THUMBNAIL_WIDTH = 1200;
export const IOS_EXPLORER_THUMBNAIL_WIDTH = 828;
export const BOOKMARK_THUMBNAIL_WIDTH = 626;
export const DEFAULT_THUMBNAIL_WIDTH = 50;
const EXPLORER_MEDIA_MAX_WIDTH = 1242; // using same max-width as iOS to share the same video URLs

export const extractMp4Link = (videoData: VideoData[]): string | undefined =>
  find(videoData, video => video.extension === VideoFileExtensionEnum.MP4)?.url;

const cloudinaryPublicIdToPosterImage = (cloudinaryPublicId: string): string =>
  resizeImage({
    url: `${CLOUDINARY_VIDEO_BASE_URL}/${cloudinaryVideoUrlVersion}/${cloudinaryPublicId}.jpg`,
    width: CLOUDINARY_VIDEO_THUMBNAIL_WIDTH,
  });

export const cloudinaryPublicIdToVideoData = (
  cloudinaryPublicId: string
): VideoPlayerMedia => ({
  posterImage: cloudinaryPublicIdToPosterImage(cloudinaryPublicId),
  videos: baseVideoData.map(videoData => ({
    ...videoData,
    url: `${CLOUDINARY_VIDEO_BASE_URL}/${cloudinaryVideoQuality}/${cloudinaryVideoUrlVersion}/${cloudinaryPublicId}.${videoData.extension}`,
  })),
});

// https://assets3.verishop.com URLs for explorer post assets
export const assets3VideoUrlToVideoData = (
  url?: string,
  thumbnailWidth: number = DEFAULT_THUMBNAIL_WIDTH,
  useFirstFrame = true,
  urlLongform = ''
): VideoPlayerMedia => {
  if (!url || !url.startsWith(CLOUDINARY_VIDEO_BASE_URL)) {
    throw new Error(`URL does not start with ${CLOUDINARY_VIDEO_BASE_URL}`);
  }

  const params = ['q_auto'];
  const thumbnailWidthParams = [
    ...params,
    useFirstFrame ? 'so_0' : 'so_auto',
    `w_${thumbnailWidth}`,
  ].join(',');
  const videoWidthParams = [...params, `w_${EXPLORER_MEDIA_MAX_WIDTH}`].join(
    ','
  );

  const urlSuffix = last(url.split(CLOUDINARY_VIDEO_BASE_URL));
  const urlSuffixWithJpg = urlSuffix?.replace('.mp4', '.jpg'); // .mp4 -> .jpg for poster image

  const posterImage = `${CLOUDINARY_VIDEO_BASE_URL}/${thumbnailWidthParams}${urlSuffixWithJpg}`;
  // Use the long-form URL if it is available
  const videoUrl = urlLongform
    ? urlLongform
    : `${CLOUDINARY_VIDEO_BASE_URL}/${videoWidthParams}${urlSuffix}`;

  return {
    posterImage,
    videos: [
      {
        extension: VideoFileExtensionEnum.MP4,
        mimeType: 'video/mp4',
        url: videoUrl,
      },
    ],
  };
};
