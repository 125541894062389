import Link from 'next/link';

import { pageRoutes } from 'lib/routes';

type TastemakerLinkProps = {
  children: any;
  slug?: string;
};

const TastemakerLink = (props: TastemakerLinkProps) => {
  const { children, slug } = props;

  if (slug) {
    const displayUrl = pageRoutes.tastemakers.detail.displayUrl(slug);

    return (
      <Link href={displayUrl} passHref prefetch={false}>
        {children}
      </Link>
    );
  } else {
    const displayUrl = pageRoutes.tastemakers.landing.displayUrl();

    return (
      <Link href={displayUrl} passHref prefetch={false}>
        {children}
      </Link>
    );
  }
};

export default TastemakerLink;
