import { resizeImage } from 'lib/image';

import { AssetFkaImage } from 'types/app';

export enum ASSET_TYPES {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  VIDEO_NO_SOUND = 'VIDEO_NO_SOUND',
}

const videoAssetTypes = new Set([
  ASSET_TYPES.VIDEO_NO_SOUND,
  ASSET_TYPES.VIDEO,
]);

export const isVideoAsset = (assetType?: ASSET_TYPES | string): boolean => {
  if (!assetType) {
    return false;
  }

  return videoAssetTypes.has(assetType as ASSET_TYPES);
};

export const decorateAssetImageWithAltText = (
  asset: AssetFkaImage,
  brand: string,
  productTitle: string
): AssetFkaImage => {
  const { type } = asset;
  const assetType = type === ASSET_TYPES.IMAGE ? 'image' : 'video';
  const altText = `${brand} ${productTitle} product ${assetType}`;
  return {
    altText,
    ...asset,
  } as AssetFkaImage;
};

export const enrichProductAsset = (asset: AssetFkaImage): AssetFkaImage => {
  const assetUrl = asset?.url;
  if (!assetUrl) {
    throw new Error('Bad asset encountered: `url` does not exist');
  }

  if (asset.type === ASSET_TYPES.IMAGE) {
    return {
      ...asset,
      thumbnailUrl: resizeImage({
        crop: 'edges',
        dpr: 2,
        fit: 'crop',
        height: 125,
        url: assetUrl,
        width: 94,
      }),
    };
  } else if (
    asset.type.includes(ASSET_TYPES.VIDEO) ||
    asset.type.includes(ASSET_TYPES.VIDEO_NO_SOUND)
  ) {
    const posterImage = assetUrl.replace(/\.mp4$/, '.jpg');
    const cloudinaryPublicId = asset?.cloudinaryPublicId;
    if (!cloudinaryPublicId) {
      throw new Error(
        'expected cloudinaryPublicId to be present in video asset'
      );
    }
    return {
      ...asset,
      thumbnailUrl: resizeImage({ height: 105, url: posterImage }),
      type: ASSET_TYPES.VIDEO_NO_SOUND, // Product video assets do not have sound
    };
  }
  throw new Error(`Could not enrich asset: unknown format ${assetUrl}`);
};
