import { ApolloError, useMutation } from '@apollo/client';
import { useEffect } from 'react';

import Logger from 'lib/utils/Logger';

import { ASSOCIATE_USER_TO_ANONYMOUS_ID } from './Identifier.gql';

import {
  AssociateUserToAnonymousIdMutation,
  AssociateUserToAnonymousIdMutationVariables,
} from 'types/generated/api';

/**
 * Associate the current user with the anonymous id.
 */
export const useAssociateUserWithAnonymousId = (
  anonymousUserId?: string | null,
  currentUserId?: string
) => {
  const [associateUserWithAnonymousId, { data, error }] = useMutation<
    AssociateUserToAnonymousIdMutation,
    AssociateUserToAnonymousIdMutationVariables
  >(ASSOCIATE_USER_TO_ANONYMOUS_ID);

  // We want to run this mutation anytime the currentUser changes.
  useEffect(() => {
    if (currentUserId && anonymousUserId && associateUserWithAnonymousId) {
      associateUserWithAnonymousId({
        variables: { anonymousUserId },
      });
    }
  }, [anonymousUserId, currentUserId, associateUserWithAnonymousId]);

  useEffect(() => {
    logAssociateUserWithAnonymousIdError(error, data);
  }, [error, data]);
};

const logAssociateUserWithAnonymousIdError = (
  error?: ApolloError,
  response?: AssociateUserToAnonymousIdMutation | null
) => {
  if (error) {
    Logger.error(
      'Error in useSendAnonymousUserIdToApi: failed to associate user with anonymous id',
      error
    );
  }

  const validationErrors =
    response?.associateUserToAnonymousId?.validationErrors;
  if (validationErrors && validationErrors.length > 0) {
    Logger.error(
      `Error in useSendAnonymousUserIdToApi: received validation errors trying to associate user with anonymous id - ${JSON.stringify(
        validationErrors
      )}`
    );
  }
};
