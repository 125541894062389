import { Asset, Entry } from 'contentful';

import { LocalizableLinkEntry } from './_shared';

export enum PlpMarketingCardAlignment {
  Left = 'Left',
  Right = 'Right',
}

export type PlpMarketingCardModel = {
  alignment?: PlpMarketingCardAlignment;
  altText?: string;
  image: Asset;
  link?: LocalizableLinkEntry;
  name: string;
  row: number;
  title?: string;
};

export type PlpMarketingCardEntry = Entry<PlpMarketingCardModel>;
