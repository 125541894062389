import { MarketingCardProps } from './MarketingCard';
import { GridScreenSize, NUMBER_OF_COLUMNS } from 'components/Grid/setup';

import {
  PlpMarketingCardAlignment,
  PlpMarketingCardEntry,
} from 'lib/contentful';

import { CARD_COLUMNS_BY_SCREEN_SIZE } from '../constants';

// The number of cards on the PLP ProductGrid that a MarketingCard takes up
export const MARKETING_CARD_MULTIPLE = 2;

type MarketingCardPropsMap = Map<number, MarketingCardProps[]>;

const computeNumberOfCardsPerRow = (screenSize: GridScreenSize): number => {
  return NUMBER_OF_COLUMNS / CARD_COLUMNS_BY_SCREEN_SIZE[screenSize];
};

// The number of cards per row for each screen size
const numberOfCardsPerRow = {
  [GridScreenSize.XSmall]: computeNumberOfCardsPerRow(GridScreenSize.XSmall),
  [GridScreenSize.Small]: computeNumberOfCardsPerRow(GridScreenSize.Small),
  [GridScreenSize.Medium]: computeNumberOfCardsPerRow(GridScreenSize.Medium),
  [GridScreenSize.Large]: computeNumberOfCardsPerRow(GridScreenSize.Large),
  [GridScreenSize.XLarge]: computeNumberOfCardsPerRow(GridScreenSize.XLarge),
};

const computeRightAlignOffset = (screenSize: GridScreenSize): number => {
  const cardsPerRow = numberOfCardsPerRow[screenSize];
  return cardsPerRow - MARKETING_CARD_MULTIPLE;
};

// The offset that gets applied to a MarketingCard that is right-aligned.
// The offset if how much the MarketingCard needs to be shifted to the right in order to be right-aligned in the row.
const rightAlignOffset = {
  [GridScreenSize.XSmall]: computeRightAlignOffset(GridScreenSize.XSmall),
  [GridScreenSize.Small]: computeRightAlignOffset(GridScreenSize.Small),
  [GridScreenSize.Medium]: computeRightAlignOffset(GridScreenSize.Medium),
  [GridScreenSize.Large]: computeRightAlignOffset(GridScreenSize.Large),
  [GridScreenSize.XLarge]: computeRightAlignOffset(GridScreenSize.XLarge),
};

const screenSizes = Object.values(GridScreenSize);

export class MarketingCardUtil {
  static buildMap(
    plpMarketingCardEntries: PlpMarketingCardEntry[] = []
  ): MarketingCardPropsMap {
    const map: MarketingCardPropsMap = new Map();

    for (const plpMarketingCardEntry of plpMarketingCardEntries) {
      for (const screenSize of screenSizes) {
        const util = new MarketingCardUtil(plpMarketingCardEntry, screenSize);
        const index = util.getIndex();
        const props = {
          numberOfColumns: util.getNumberOfColumns(),
          plpMarketingCardEntry,
          screenSize,
        };

        const existingProps = map.get(index);
        if (existingProps) {
          existingProps.push(props);
        } else {
          map.set(index, [props]);
        }
      }
    }

    return map;
  }

  /**
   * Computes the number of cards the the MarketingCards will take up in the PLP ProductGrid.
   */
  static getNumberOfMarketingCards(
    plpMarketingCardEntries: PlpMarketingCardEntry[] = []
  ) {
    const numberOfPlpMarketingEntries = plpMarketingCardEntries.length;
    return numberOfPlpMarketingEntries * MARKETING_CARD_MULTIPLE;
  }

  private readonly row: number;
  private readonly cardsPerRow: number;
  private readonly productCardColumns: number;
  private readonly rightAlignOffset: number;

  constructor(
    plpMarketingCardEntry: PlpMarketingCardEntry,
    screenSize: GridScreenSize
  ) {
    const { alignment, row } = plpMarketingCardEntry.fields;
    this.row = row;
    this.cardsPerRow = numberOfCardsPerRow[screenSize];
    this.productCardColumns = CARD_COLUMNS_BY_SCREEN_SIZE[screenSize];

    // If a MarketingCard is right-aligned, the rightAlignOffset determines how much the MarketingCard
    // needs to be shifted to the right in order to be right-aligned in the ProductGrid row.
    this.rightAlignOffset =
      alignment === PlpMarketingCardAlignment.Right
        ? rightAlignOffset[screenSize]
        : 0;
  }

  /**
   * The index within the ProductGrid where the MarketingCard should be placed.
   */
  getIndex(): number {
    const row = this.row - 1; // Subtract by 1 so that MarketingCards can be added to the 1st row, rather than after the 1st row.
    return row * this.cardsPerRow + this.rightAlignOffset;
  }

  /**
   * The number of Grid columns the MarketingCard should span for a given screenSize
   * Gets the number of columns a ProductCard spans and multiplies it by some multiple.
   */
  getNumberOfColumns(): number {
    return this.productCardColumns * MARKETING_CARD_MULTIPLE;
  }
}
