export enum TAB_ID {
  COLLECTIONS = 'collections',
  LIVESTREAMS = 'livestreams',
  POSTS = 'posts',
  PRODUCTS = 'products',
}

export const TabName = {
  collections: {
    id: TAB_ID.COLLECTIONS,
    name: 'Collections',
    tabName: 'Collections',
  },
  livestreams: {
    id: TAB_ID.LIVESTREAMS,
    name: 'Livestreams',
    tabName: 'Livestreams',
  },
  posts: {
    id: TAB_ID.POSTS,
    name: 'Posts',
    tabName: 'Explore',
  },
  products: {
    id: TAB_ID.PRODUCTS,
    name: 'Products',
    tabName: 'Products',
  },
};

export const DefaultBrandTab = TAB_ID.PRODUCTS;
export const DefaultProfileTab = TAB_ID.POSTS;
