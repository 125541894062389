import { Document } from '@contentful/rich-text-types';
import to from 'await-to-js';
import { Entry } from 'contentful';

import { client } from './index';

export const StaticPageContentfulIds = {
  COMMUNITY_GUIDELINES_PAGE: '4TJcfQTV9pHhmTRPQaFThe',
  MEDIA_PAGE: '2kijWIUC9ytCiEW8SDk31i',
  PRIVACY_POLICY_PAGE: 'JZF5wCQMa7iHmnt0YwQli',
  RETURN_POLICY_PAGE: '1khNf1z41oZN7JHA6rX3ph',
  TERMS_OF_SERVICE: '794MjC8Kd2dp0aKYeE3zZl',
};

export interface IStaticPageModel {
  content: Document;
  heading: string;
  metaDescription?: string;
  metaTitle?: string;
  updatedAt: string;
}

export const loadStaticPageData = async (id: string) => {
  const [error, response] = await to<Entry<IStaticPageModel>>(
    client.getEntry<IStaticPageModel>(id)
  );

  if (error) {
    throw new Error(
      `Error retrieving static page data for ID: ${id}; Error Message: ${error}`
    );
  }

  return response;
};
