import Link from 'next/link';
import { ReactElement } from 'react';

import { pageRoutes } from 'lib/routes';

type CategoryLinkProps = {
  categorySlug: string;
  children?: ReactElement | ReactElement[];
  passHref?: boolean;
  queryParams?: Record<string, unknown>;
};

const CategoryLink = ({
  categorySlug,
  children,
  passHref,
  queryParams,
}: CategoryLinkProps): ReactElement => {
  const displayUrl = pageRoutes.category.plp.displayUrl(
    categorySlug,
    queryParams
  );

  return (
    <Link href={displayUrl} passHref={passHref} prefetch={false}>
      {children}
    </Link>
  );
};

export default CategoryLink;
