import { gql } from '@apollo/client';

import fragments from './fragments';

const ADD_TO_CART = gql`
  mutation checkoutLineItemsAdd(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...checkoutFields
      }
      userErrors {
        message
        field
      }
    }
  }
  ${fragments.checkoutFields}
`;

const CHECKOUT_DISCOUNT_CODE_APPLY = gql`
  mutation checkoutDiscountCodeApplyV2(
    $discountCode: String!
    $checkoutId: ID!
  ) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        ...checkoutFields
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${fragments.checkoutFields}
`;

const CHECKOUT_DISCOUNT_CODE_REMOVE = gql`
  mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ...checkoutFields
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${fragments.checkoutFields}
`;

const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation checkoutLineItemsReplace(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...checkoutFields
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${fragments.checkoutFields}
`;

const CREATE_CHECKOUT_ID = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

const CHECKOUT_ATTRIBUTES_UPDATE_V2 = gql`
  mutation checkoutAttributesUpdateV2(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
  ) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

const APPEND_GIFT_CARD_CODE = gql`
  mutation verishopWebAppendGiftCardCode(
    $giftCardCodes: [String!]!
    $checkoutId: ID!
  ) {
    checkoutGiftCardsAppend(
      giftCardCodes: $giftCardCodes
      checkoutId: $checkoutId
    ) {
      checkout {
        id
        appliedGiftCards {
          id
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

const REMOVE_GIFT_CARD_CODE = gql`
  mutation verishopWebRemoveGiftCardCode(
    $appliedGiftCardId: ID!
    $checkoutId: ID!
  ) {
    checkoutGiftCardRemoveV2(
      appliedGiftCardId: $appliedGiftCardId
      checkoutId: $checkoutId
    ) {
      checkout {
        id
      }
    }
  }
`;

export {
  ADD_TO_CART,
  APPEND_GIFT_CARD_CODE,
  CHECKOUT_ATTRIBUTES_UPDATE_V2,
  CHECKOUT_DISCOUNT_CODE_APPLY,
  CHECKOUT_DISCOUNT_CODE_REMOVE,
  CHECKOUT_LINE_ITEMS_REPLACE,
  CREATE_CHECKOUT_ID,
  REMOVE_GIFT_CARD_CODE,
};
