import {
  client,
  ContentfulContentTypeEnum,
  RichTextModuleEntry,
  RichTextModuleModel,
} from './index';

export type CartPageContentType = {
  returnPolicyModal: RichTextModuleModel;
};

/**
 * Fetches the Brands Landing Page data model in Contentful
 */
export const loadCartPageContent = async (): Promise<CartPageContentType> => {
  const returnPolicyModalEntry = await client.getEntries<RichTextModuleEntry>({
    content_type: ContentfulContentTypeEnum.richTextModal,
    'fields.id': 'returnsAndRefundsPolicyModal',
    include: 1,
    limit: 1,
  });

  return {
    returnPolicyModal: { ...returnPolicyModalEntry?.items?.[0]?.fields },
  };
};
