import { useEffect } from 'react';

import { identifyUser } from './identify';

import { isBrowser } from 'lib/utils/browser';

import { useExperiments } from '../experiment';

/**
 * Identifies the currentUser with experiments
 * It will also identify with Segment and record the experiments
 */
export const useIdentifyAndExperimentsToSegment = (
  anonymousId?: string | null,
  authContext?: {
    email: string;
    userId: string;
  }
): void => {
  const isInBrowser = isBrowser();

  // Empty exposures as we just send identifier event
  const { experiments } = useExperiments();

  useEffect(() => {
    // At least one ID available to proceed in browser
    if (isInBrowser && (anonymousId || authContext)) {
      identifyUser(experiments && { experiments }, anonymousId, authContext);
    }
  }, [isInBrowser, anonymousId, authContext, experiments]);
};
