import classnames from 'classnames';

import { PrivacyPolicyLink, TermsLink } from 'lib/links';

import styles from './VerishopAgreementText.module.scss';

type VerishopAgreementTextProps = {
  actionText: string;
  className?: string;
  suffixText?: string;
};

const VerishopAgreementText = ({
  actionText,
  className,
  suffixText,
}: VerishopAgreementTextProps) => {
  return (
    <div className={classnames(styles.termsContainer, className)}>
      By {actionText}, you agree to Verishop’s{' '}
      <TermsLink>
        <a href="placeholder" target="_blank">
          Terms of Use
        </a>
      </TermsLink>{' '}
      and{' '}
      <PrivacyPolicyLink>
        <a href="placeholder" target="_blank">
          Privacy Policy
        </a>
      </PrivacyPolicyLink>
      .<div>{suffixText}</div>
    </div>
  );
};

export default VerishopAgreementText;
