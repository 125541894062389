import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { CartLineItemProps } from 'containers/CartPage/LineItem/CartLineItem';
import { LineItemCustomAttributeKeys } from 'data/graphql/enums';
import { CustomAttribute } from 'data/graphql/types.shopify';

import Logger from 'lib/utils/Logger';

import { CartLineItemsBySkuVariant } from 'containers/CartPage/CartPage.gql';

import { CartLineItem, DiscountAllocation } from 'data/graphql/types';
import { ProductBundle } from 'types/app';
import { ProductVariant } from 'types/generated/api';

// TODO BUNDLES: maybe we should create types/cart? or we can move other cart related types here
export type CartLineItemObject =
  | CartLineItemObjectShopify
  | CartLineItemObjectBundle;

export type CartLineItemObjectShopify = {
  lineItem: CartLineItemProps;
  type: CartLineItemType.SHOPIFY;
};

export type CartLineItemObjectBundle = {
  lineItem: ProductBundle;
  type: CartLineItemType.BUNDLE;
};

export type ClassifyCartLineItemInput = Pick<
  ProductVariant,
  'isProductBundle' | 'sku'
> &
  Pick<CartLineItem, 'customAttributes'>;

export enum CartLineItemType {
  BUNDLE = 'BUNDLE',
  SHOPIFY = 'SHOPIFY_VARIANT',
}

export const buildBundles = (
  parents: CartLineItemsBySkuVariant[],
  pieces: CartLineItemProps[]
): ProductBundle[] => {
  const bundles: ProductBundle[] = [];

  parents.map(parent => {
    const bundle: ProductBundle = {
      children: pieces.filter(child => getBundleId(child) === parent.sku),
      parent,
    };

    bundles.push(bundle);
  });

  return bundles;
};

export const classifyCartLineItems = (
  cartLineItems: CartLineItemProps[],
  bundlesBySkuVariants: CartLineItemsBySkuVariant[]
): CartLineItemObject[] => {
  try {
    const bundleParents = bundlesBySkuVariants;
    const bundlePieces: CartLineItemProps[] = [];
    const products: CartLineItemProps[] = [];

    cartLineItems.forEach(item => {
      if (isItemPartOfBundle(item)) {
        bundlePieces.push(item);
      } else {
        products.push(item);
      }
    });

    const bundles = buildBundles(bundleParents, bundlePieces) || [];
    const bundleObjects = bundles.map(bundle => ({
      lineItem: bundle,
      type: CartLineItemType.BUNDLE as CartLineItemType.BUNDLE, // Have to use typecasting here since TS won't narrow the type of the enum.
    }));
    const productObjects = products.map(product => ({
      lineItem: product,
      type: CartLineItemType.SHOPIFY as CartLineItemType.SHOPIFY, // Have to use typecasting here since TS won't narrow the type of the enum.
    }));

    return [...bundleObjects, ...productObjects];
  } catch (error) {
    Logger.error(
      'Something went wrong converting cart line items to bundles/products'
    );
    return [];
  }
};

export const getBundleId = <T extends Pick<CartLineItem, 'customAttributes'>>(
  item: T
): string | undefined =>
  item?.customAttributes?.find(
    (attribute: CustomAttribute) =>
      attribute.key === LineItemCustomAttributeKeys.SELECTED_BUNDLE_ID
  )?.value;

export const getBundleSkus = (items?: CartLineItem[]): string[] => {
  const bundleSkus: string[] = [];

  if (!isEmpty(items)) {
    items?.forEach((item: CartLineItem) => {
      const bundleId = getBundleId(item);
      if (bundleId) {
        bundleSkus.push(bundleId);
      }
    });
  }

  return uniq(bundleSkus);
};

export const isItemPartOfBundle = (item: CartLineItemProps): boolean =>
  !!getBundleId(item);

export const separateBundlesAndVariants = (
  lineItems: CartLineItemsBySkuVariant[]
) => {
  try {
    const bundlesBySkuVariants: CartLineItemsBySkuVariant[] = [];
    const lineItemsBySkuVariants: CartLineItemsBySkuVariant[] = [];

    lineItems.forEach(item => {
      if (item.isProductBundle) {
        bundlesBySkuVariants.push(item);
      } else {
        lineItemsBySkuVariants.push(item);
      }
    });

    return {
      bundlesBySkuVariants,
      lineItemsBySkuVariants,
    };
  } catch (error) {
    Logger.error('Something went wrong running separateBundlesAndVariants');
    return {
      bundlesBySkuVariants: [],
      lineItemsBySkuVariants: [],
    };
  }
};

export const isCartLineItemObjectBundle = (
  cartLineItemObject: CartLineItemObject
): cartLineItemObject is CartLineItemObjectBundle => {
  return cartLineItemObject.type === CartLineItemType.BUNDLE;
};

export const isCartLineItemObjectShopify = (
  cartLineItemObject: CartLineItemObject
): cartLineItemObject is CartLineItemObjectShopify => {
  return cartLineItemObject.type === CartLineItemType.SHOPIFY;
};

// Since each lineItem can have discounts, we need to calculate the sum of their price,
// as opposed to the pseudo bundle variant price
export const getBundlePrice = (lineItems: CartLineItemProps[]) => {
  const bundlePrice = lineItems.reduce(
    (accumulator, lineItem) =>
      accumulator + +lineItem.price * lineItem.quantity,
    0
  );

  return bundlePrice.toFixed(2);
};

// This maps a bundle's items skus to their respective quantity count
export const getBundleItemSkuToQuantityMap = (
  lineItems: CartLineItemProps[],
  bundleQuantity: number
) => {
  const bundleItemToSkuMap: { [key: string]: number } = {};
  lineItems?.map(lineItem => {
    bundleItemToSkuMap[lineItem.sku] = lineItem.quantity / bundleQuantity;
  });

  return bundleItemToSkuMap;
};

// Shopify uniquely identifies a variant via sid. Since a variant
// can be a part of a bundle AND also an individual item, we generate a composite key by
// appending the bundle sku to the end if the item belongs to a bundle
export const getCompositeLineItemKey = (
  id: string,
  customAttributes: CustomAttribute[]
) => {
  try {
    let compositeKey = id;
    const bundleId = getBundleId({ customAttributes });

    if (bundleId) {
      compositeKey += `~${bundleId}`;
    }

    return compositeKey;
  } catch (error) {
    Logger.error(
      'Something went wrong creating a composite key for a cart line item: ',
      error
    );

    return id;
  }
};

/**
 * Bundles get a special discount that is applied via Shopify script
 * This util will get the total amount of discounts that were applied to
 * all line items that are bundleItems.
 */
export const getTotalBundleDiscount = (
  lineItems: Array<{
    discountAllocations: DiscountAllocation[];
  }>
): number => {
  let sum = 0;
  for (const lineItem of lineItems) {
    const { discountAllocations } = lineItem;
    const bundleDiscountAllocation = discountAllocations.find(
      discountAllocation =>
        discountAllocation.discountApplication.title?.includes('Bundle Savings')
    );
    const bundleDiscountAmount =
      bundleDiscountAllocation?.allocatedAmount.amount;

    if (bundleDiscountAmount) {
      sum += parseFloat(bundleDiscountAmount) * 100; // multiple by 100 to avoid rounding errors
    }
  }

  return sum / 100;
};
