import IDefaultComponentProps from 'components/defaultComponentProps';

import { useSignOut } from 'lib/hooks/auth/useSignOut';
import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

export type LogoutLinkProps = { label?: string } & IDefaultComponentProps;

export const DefaultLogoutLabel = 'Logout';

const LogoutLink = ({
  className,
  label = DefaultLogoutLabel,
}: LogoutLinkProps) => {
  const handleSignOut = useSignOut();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={className}
      onClick={handleSignOut}
      onKeyPress={keyPressHandler({ Enter: handleSignOut })}
      role="button"
      tabIndex={0}
    >
      {label}
    </a>
  );
};

export default LogoutLink;
