import { trackEvent } from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';

import { WishlistProductAddToCartProperties } from 'types/segment';

export type ProductAddedOrRemovedFromWishlistProperties = {
  brand: string; // Brand associated with the product
  category: string; // Product category being viewed
  name: string; // Name of the product being viewed
  position?: number; // Position in the product list (ex. 3). A bookmark on a PDP does not require a position.
  price?: number; // Price ($) of the product being viewed
  product_id: string; // Database id of the product being viewed
  variant_id?: string | null; // The variant's sid if a full variant has been selected
  wishlist_id: string; // BookmarkList ID to which the product was added to
  wishlist_name: string; // BookmarkList name to which the product was added to
};

export const trackProductAddedToWishlist = (
  properties: ProductAddedOrRemovedFromWishlistProperties
) => trackEvent(AnalyticsEvent.PRODUCT_ADDED_TO_WISHLIST, properties);

export const trackProductRemovedFromWishlist = (
  properties: ProductAddedOrRemovedFromWishlistProperties
) => trackEvent(AnalyticsEvent.PRODUCT_REMOVED_FROM_WISHLIST, properties);

export const trackWishListCreated = (listId: string) =>
  trackEvent(AnalyticsEvent.WISHLIST_CREATED, { list_id: listId });

export const trackWishListDeleted = (listId: string) =>
  trackEvent(AnalyticsEvent.WISHLIST_REMOVED, { list_id: listId });

export const trackWishListEdited = (listId: string) =>
  trackEvent(AnalyticsEvent.WISHLIST_UPDATED, { list_id: listId });

export const trackWishListViewed = (listId: string) =>
  trackEvent(AnalyticsEvent.WISHLIST_VIEWED, { list_id: listId });

export const trackWishlistProductAddedToCart = (
  properties: WishlistProductAddToCartProperties
) => trackEvent(AnalyticsEvent.WISHLIST_PRODUCT_ADDED_TO_CART, properties);
