import { Asset, Entry } from 'contentful';

import { ILocalizableLink } from './_shared';

export enum NavSubmenuSectionTypes {
  BRANDS_SHORTCUTS = 'brandsShortcuts',
  FEATURED = 'featured',
  LINKS = 'links',
  SUBCATEGORY = 'subcategory',
}

export interface INavMenuColumnModel {
  position: number;
  sections: Array<Entry<INavMenuSectionModel>>;
  title: string;
}

export interface INavMenuSectionModel {
  id: string;
  items: Array<Entry<ILocalizableLink>>;
  summaryLink: Entry<ILocalizableLink>;
  title: string;
  titlePathName: string;
  type?: NavSubmenuSectionTypes;
}

export interface INavMenuSubmenuModel {
  bannerBottomLink: Entry<ILocalizableLink>;
  bannerImage: Asset;
  bannerImageMobile: Asset;
  columns: Array<Entry<INavMenuColumnModel>>;
  id: string;
}

export interface INavMenuTopMenuItemModel {
  fontColor?: string;
  id: string;
  link: Entry<ILocalizableLink>;
  submenu: Entry<INavMenuSubmenuModel>;
}

export interface INavMenuModel {
  featuredLink?: Entry<ILocalizableLink>;
  fontColor?: string;
  menuItems: Array<Entry<INavMenuTopMenuItemModel>>;
}

export interface ISideNavigationMenuBanner {
  image: Asset;
  url: string;
}
