import { setConfiguration } from 'react-grid-system';

export const NUMBER_OF_COLUMNS = 12;

export enum GridScreenSize {
  Large = 'lg',
  Medium = 'md',
  Small = 'sm',
  XLarge = 'xl',
  XSmall = 'xs',
}

export enum GridBreakPoints {
  Large = 1440,
  Medium = 1024,
  Small = 640,
  XLarge = 1600,
  XSmall = 420,
}

/* Add outside margins:
  XS - 8px
  S - 12px
  M - 20px
  L - 68px
*/
setConfiguration({
  breakpoints: [
    GridBreakPoints.Small,
    GridBreakPoints.Medium,
    GridBreakPoints.Large,
    GridBreakPoints.XLarge,
  ],
  containerWidths: [1580, 1400, 1580, 1580], // "max" container width
  defaultScreenClass: GridScreenSize.XSmall,
  gridColumns: NUMBER_OF_COLUMNS,
  gutterWidth: 24,
});
