import Link from 'next/link';
import { ReactElement } from 'react';

import { getProfileCollectionUrlByProfile } from 'explorer/lib/utils/profile';

import { Profile } from 'types/generated/api';

type BookmarkListPLPLinkProps = {
  bookmarkListSlug: string;
  children: ReactElement;
  collectionProfile?: Profile;
};

const BookmarkListPLPLink = ({
  bookmarkListSlug,
  children,
  collectionProfile,
}: BookmarkListPLPLinkProps) => {
  const displayUrl = getProfileCollectionUrlByProfile(
    bookmarkListSlug,
    collectionProfile
  );

  return (
    <Link href={displayUrl} passHref prefetch={false}>
      {children}
    </Link>
  );
};

export default BookmarkListPLPLink;
