import { Entry } from 'contentful';

import { client, ContentfulContentTypeEnum } from './index';

type RecommendationsModel = {
  recommendations: RecommendationSectionEntry[];
  slug: string;
};

type RecommendationSectionModel = {
  modelId: string;
  modelVariantId: string;
  title: string;
};

export type RecommendationsEntry = Entry<RecommendationsModel>;
export type RecommendationSectionEntry = Entry<RecommendationSectionModel>;

export const loadRecommendations = async (
  recommendationSlug: string
): Promise<RecommendationsEntry> => {
  const response = await client.getEntries<RecommendationsModel>({
    content_type: ContentfulContentTypeEnum.recommendations,
    'fields.slug': recommendationSlug,
    include: 3,
    limit: 1,
  });

  return response.items[0];
};
