/**
 * This file implements https://schema.org/VideoObject
 */

import compact from 'lodash/compact';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { JsonLd } from 'react-schemaorg';
import { VideoObject } from 'schema-dts';

import {
  cloudinaryPublicIdToVideoData,
  extractMp4Link,
} from 'components/VideoPlayer/utils';

import textFormatter from 'lib/textFormatter';
import Logger from 'lib/utils/Logger';

import { ProductVariant } from 'types/api';
import { AssetFkaImage } from 'types/app';
import { AssetType, Product, Video as VideoAsset } from 'types/generated/api';

type VideoLinkedDataProps = {
  video: VideoObject;
};

/**
 * Extracts the first video in galleryAssets
 */
export const extractVideoAssetFromGalleryAssets = (
  galleryAssets: AssetFkaImage[] = []
): VideoAsset | undefined => {
  const videos = galleryAssets.filter(asset => asset.type === AssetType.VIDEO);
  return first(videos) as VideoAsset;
};

const getMinimumPublishDate = (
  variants: Array<Pick<ProductVariant, 'publishDate'>> = []
): string | undefined => {
  try {
    const publishDates = compact(variants.map(variant => variant.publishDate));
    const minimumPublishDate = first(
      publishDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    );
    return minimumPublishDate
      ? textFormatter.formatDate(minimumPublishDate, 'yyyy-MM-dd') ?? undefined
      : undefined;
  } catch (_) {
    return undefined;
  }
};

type GenerateProductVideoObjectProps = {
  product?: Pick<Product, 'brand' | 'variants' | 'sid' | 'title'>;
  video?: Partial<AssetFkaImage>;
};

export const generateProductVideoObject = ({
  product,
  video,
}: GenerateProductVideoObjectProps): VideoObject | undefined => {
  try {
    if (!video || !video.cloudinaryPublicId) {
      Logger.warn(`Missing product video metadata for sid:${product?.sid}`);
      return undefined;
    }
    const mediaObjects = cloudinaryPublicIdToVideoData(
      video.cloudinaryPublicId
    );
    const datePublished = getMinimumPublishDate(product?.variants);
    const uploadDate = datePublished;

    const videoLinkedData: VideoObject = {
      '@type': 'VideoObject',
      contentUrl: extractMp4Link(mediaObjects.videos),
      datePublished,
      description: `Product video of ${product?.title} ${product?.brand}`,
      name: video.altText || 'Product video',
      thumbnailUrl: mediaObjects.posterImage,
      uploadDate,
    };

    return videoLinkedData;
  } catch (error) {
    Logger.warn('Unable to generate product video metadata', error);
    return undefined;
  }
};

export const VideoLinkedData = ({ video }: VideoLinkedDataProps) => {
  if (isEmpty(video)) {
    return null;
  }
  try {
    return (
      <JsonLd<VideoObject>
        item={{
          '@context': 'https://schema.org',
          ...video,
        }}
      />
    );
  } catch (error) {
    Logger.warn('Unable to generate Video structured data', error);
    return null;
  }
};
