import classnames from 'classnames';
import { InputHTMLAttributes } from 'react';

import styles from './TextInput.module.scss';

interface ITextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  classNameInput?: string;
  errorMessage?: string | null;
  hasError?: boolean;
  label?: string;
  prefix?: string;
  type?: string;
  value?: string;
}

const TextInput = (props: ITextInputProps) => {
  const {
    className,
    classNameInput,
    errorMessage,
    hasError,
    label,
    prefix,
    ...rest
  } = props;

  return (
    <label className={classnames(styles.root, className)}>
      {label && <div className={styles.labelText}>{label}</div>}
      <div
        className={classnames(
          styles.inputContainer,
          {
            [styles.error]: hasError || errorMessage,
          },
          classNameInput
        )}
      >
        {prefix && <span className={styles.prefixText}>{prefix}</span>}
        <input {...rest} className={styles.input} />
      </div>

      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </label>
  );
};

export default TextInput;

TextInput.defaultProps = {
  hasError: false,
  type: 'text',
  value: '',
};
