import Link from 'next/link';
import { ReactElement } from 'react';

import { pageRoutes } from 'lib/routes';

type AccountEditLinkProps = {
  children: ReactElement;
  passHref?: boolean;
};

const AccountEditLink = ({
  children,
  passHref,
}: AccountEditLinkProps): ReactElement => {
  const displayUrl = pageRoutes.account.edit.displayUrl();

  return (
    <Link href={displayUrl} passHref={passHref} prefetch={false}>
      {children}
    </Link>
  );
};

export default AccountEditLink;
